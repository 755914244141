import { Row, Col } from 'react-bootstrap'

import { Heading } from 'components/Typography/Heading'
import { Paragraph } from 'components/Typography/Paragraph'

import { Container } from './styles'

interface Props {
  title?: string
  description?: string
}

export function Loader({ title, description }: Props) {
  return (
    <Container data-testid="loader">
      <Row className="mb-4">
        <Col>
          <div className="loader" />
        </Col>
      </Row>

      <Row className="mb-3">
        <Col>
          <Heading type="huge">{title || ''}</Heading>
        </Col>
      </Row>

      <Row>
        <Col>
          <Paragraph>{description || ''}</Paragraph>
        </Col>
      </Row>
    </Container>
  )
}

Loader.defaultProps = {
  title: undefined,
  description: undefined
}
