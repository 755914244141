import { useEffect } from 'react'

import { useLazyQuery } from '@apollo/client'

import {
  ILogoutRequest,
  ILogoutResponse,
  LOGOUT
} from 'services/graphql/queries/Logout'

import { useLoaderContext } from 'contexts/Loader'

export function useLogout() {
  const { showLoader, hideLoader } = useLoaderContext()

  const [logout, { loading }] = useLazyQuery<ILogoutResponse, ILogoutRequest>(
    LOGOUT
  )

  useEffect(() => {
    loading ? showLoader() : hideLoader()
  }, [loading, showLoader, hideLoader])

  return { logout }
}
