import styled, { css } from 'styled-components'

export const Container = styled.div`
  display: flex;
  gap: 40px;
  flex-direction: column;
  margin-top: 40px;
`

export const Description = styled.p`
  ${props => css`
    color: ${props.theme.colors.global.content[500]};
    font-size: ${props.theme.font.size[16]};
    font-weight: ${props.theme.font.weight[700]};
    line-height: ${props.theme.line.height[125]};
    white-space: pre-line;

    @media (max-width: 768px) {
      font-weight: ${props.theme.font.weight[500]};
    }
  `}
`
