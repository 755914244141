import {
  ApolloLink,
  ApolloClient,
  InMemoryCache,
  DefaultOptions,
  HttpLink,
  Observable
} from '@apollo/client'

import { onError } from '@apollo/client/link/error'

import {
  ROUTE_LOGIN,
  ROUTE_SECOND_SESSION_EXPIRED,
  ROUTE_OFFLINE_ERROR
} from 'constants/routes'

import { REFRESH_TOKEN } from 'services/graphql/queries/RefreshToken'

import { alphanumericMask } from 'utils/masks'

import {
  getLocalStorageItem,
  setLocalStorageItem,
  removeLocalStorageItem
} from 'utils/storage/local'

export const client = () => {
  const httpLink = new HttpLink({
    uri: process.env.REACT_APP_BFF_GRAPHQL
  })

  const authLink = new ApolloLink((operation, forward) => {
    const user = getLocalStorageItem('RD@Onboarding:user')

    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        authorization: user?.auth?.token ? `Bearer ${user.auth.token}` : null
      }
    }))

    return forward(operation)
  })

  const errorLink = onError(
    ({ graphQLErrors, networkError, operation, forward }) => {
      if (graphQLErrors) {
        const { message, path } = graphQLErrors[0]

        /* eslint-disable no-console */
        console.log(`[GraphQL Error]: Message: ${message}, Path: ${path}`)
        /* eslint-enable no-console */

        const isLoginPath = path?.includes(alphanumericMask(ROUTE_LOGIN))

        if (message === 'Unauthorized' && !isLoginPath) {
          return new Observable(observer => {
            refreshToken()
              .then(token => {
                operation.setContext(({ headers = {} }) => ({
                  headers: {
                    ...headers,
                    authorization: `Bearer ${token}` || null
                  }
                }))
              })
              .then(() => {
                const subscriber = {
                  next: observer.next.bind(observer),
                  error: observer.error.bind(observer),
                  complete: observer.complete.bind(observer)
                }

                forward(operation).subscribe(subscriber)
              })
              .catch(error => {
                observer.error(error)
              })
          })
        }
      }

      if (networkError) {
        /* eslint-disable no-console */
        console.log(`[Network Error]: ${networkError}`)
        /* eslint-enable no-console */

        window.location.replace(ROUTE_OFFLINE_ERROR)
      }

      return undefined
    }
  )

  const defaultOptions: DefaultOptions = {
    watchQuery: {
      fetchPolicy: 'no-cache',
      nextFetchPolicy: 'no-cache',
      errorPolicy: 'all',
      refetchWritePolicy: 'overwrite'
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all'
    }
  }

  return new ApolloClient({
    cache: new InMemoryCache({
      addTypename: false
    }),
    link: ApolloLink.from([errorLink, authLink, httpLink]),
    defaultOptions
  })
}

export const refreshToken = async () => {
  const user = getLocalStorageItem('RD@Onboarding:user')

  return client()
    .query({
      query: REFRESH_TOKEN,
      variables: { refreshToken: user.auth?.refreshToken }
    })
    .then(response => {
      const { access_token, expires_in, refresh_token } =
        response.data.refreshToken

      const startedDate = new Date()
      const expirationDate = new Date(startedDate)
      expirationDate.setSeconds(
        expirationDate.getSeconds() + Number(expires_in)
      )

      const newUser = {
        username: user.username,
        auth: {
          expiresIn: expirationDate.getTime(),
          token: access_token,
          refreshToken: refresh_token
        }
      }

      setLocalStorageItem('RD@Onboarding:user', newUser)

      return newUser.auth.token
    })
    .catch(() => {
      removeLocalStorageItem('RD@Onboarding:user')

      window.location.replace(ROUTE_SECOND_SESSION_EXPIRED)
    })
}
