import gql from 'graphql-tag'

export interface ILogoutRequest {
  refreshToken: string
}

export interface ILogoutResponse {
  logout: {
    refresh_token: string
  }
}

export const LOGOUT = gql`
  query logout($refreshToken: String!) {
    logout(input: { refresh_token: $refreshToken }) {
      refresh_token
    }
  }
`
