import styled, { css } from 'styled-components'

import { rgba } from 'polished'

export const Container = styled.div`
  ${props => css`
    background-color: ${rgba(props.theme.colors.global.background[400], 0.5)};
    height: 100vh;
    left: 0;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: 1020;
  `}
`
