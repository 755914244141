import { Suspense } from 'react'

import { Routes, Route, Navigate } from 'react-router-dom'

import routes from 'routes'

import { RequireAuth } from 'routes/Routing/RequireAuth'

import { Loader } from 'components/Loader'

import { NotFoundError } from 'pages/Errors/NotFound'

export function Routing() {
  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        <Route path="/">
          {routes.map(({ path, component: Component, isPrivate, role }) => (
            <Route
              key={path}
              path={path}
              element={
                <RequireAuth isPrivate={isPrivate} role={role}>
                  <Component />
                </RequireAuth>
              }
            />
          ))}
          <Route path="/" element={<Navigate to="/categories" replace />} />
          <Route path="*" element={<NotFoundError />} />
        </Route>
      </Routes>
    </Suspense>
  )
}
