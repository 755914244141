import { useState, useEffect } from 'react'

import { Row, Col } from 'react-bootstrap'

import { useLocation } from 'react-router-dom'

import { isDesktop } from 'react-device-detect'

import { useTheme } from 'styled-components'

import Logo from 'assets/images/logo.png'
import LogoMobile from 'assets/images/logo-mobile.png'

import {
  ROUTE_SECOND_JOURNEY,
  ROUTE_EDIT_COMMERCIAL_DATA,
  ROUTE_EDIT_BILLING_ADDRESS,
  ROUTE_EDIT_CONTRACT
} from 'constants/routes'

import { useSideMenuContext } from 'contexts/Layout/SideMenu'

import { ButtonMenu } from 'components/Layout/Header/components/ButtonMenu'

import { Container } from './styles'

export function Header() {
  const location = useLocation()

  const { colors } = useTheme()

  const { buttonVisible } = useSideMenuContext()

  const [mobileBgColor, setMobileBgColor] = useState(
    colors.global.background.white
  )

  useEffect(() => {
    window.scrollTo({ top: 0 })

    switch (location.pathname) {
      case ROUTE_SECOND_JOURNEY:
      case ROUTE_EDIT_COMMERCIAL_DATA:
      case ROUTE_EDIT_BILLING_ADDRESS:
      case ROUTE_EDIT_CONTRACT:
        setMobileBgColor(colors.global.background[100])
        break

      default:
        setMobileBgColor(colors.global.background.white)
        break
    }
  }, [location, colors])

  return (
    <div data-testid="header">
      <Container bgColor={mobileBgColor}>
        <Row style={{ height: '100%' }}>
          {buttonVisible ? (
            <Col xs={{ span: 2, order: 2 }} md={{ span: 2, order: 1 }}>
              <Row className="justify-content-sm-end justify-content-md-start">
                <Col>
                  <ButtonMenu data-testid="menu-button" />
                </Col>
              </Row>
            </Col>
          ) : null}

          <Col
            xs={{ span: 10, order: 1, offset: buttonVisible ? 0 : 1 }}
            md={{ span: 8, order: 2, offset: buttonVisible ? 0 : 2 }}
            className="my-auto text-center"
          >
            <img src={isDesktop ? Logo : LogoMobile} alt="Raia Drogasil" />
          </Col>
        </Row>
      </Container>
    </div>
  )
}
