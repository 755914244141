import { Row, Col } from 'react-bootstrap'

import { isMobile } from 'react-device-detect'

import { useDialogConfirmationContext } from 'contexts/DialogConfirmation'

import { Modal } from 'components/Modal'
import { Button } from 'components/DesignSystem/Button'

import { Container, Description } from './styles'

export interface IConfirmationDialog {
  title: string
  description: string
  cancelTxt: string
  confirmTxt: string
  onCancel?: () => void
  onConfirm: () => void
}

export function Dialog({
  title,
  description,
  cancelTxt,
  confirmTxt,
  onCancel,
  onConfirm
}: IConfirmationDialog) {
  const { removeConfirmation } = useDialogConfirmationContext()

  return (
    <Modal title={title} onClose={() => removeConfirmation()}>
      <Container data-testid="dialog">
        <Row>
          <Col>
            <Description>{description}</Description>
          </Col>
        </Row>

        <Row className={`justify-content-end ${isMobile ? 'gap-3' : ''}`}>
          <Col xs={12} sm="auto">
            <Button
              label={cancelTxt}
              styles="secondary"
              display="block"
              dataTestId="dialog-cancel"
              onClick={() => {
                onCancel && onCancel()
                removeConfirmation()
              }}
            />
          </Col>

          <Col xs={12} sm="auto">
            <Button
              label={confirmTxt}
              styles="primary"
              display="block"
              dataTestId="dialog-confirm"
              onClick={() => {
                onConfirm()
                removeConfirmation()
              }}
            />
          </Col>
        </Row>
      </Container>
    </Modal>
  )
}

Dialog.defaultProps = {
  onCancel: undefined
}
