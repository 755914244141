import styled, { css } from 'styled-components'

import { rgba } from 'polished'

import { isMobile } from 'react-device-detect'

interface ItemProps {
  active?: boolean
}

export const Container = styled.div`
  ${props => css`
    background-color: ${props.theme.colors.global.background[100]};

    display: flex;
    flex-direction: column;

    height: calc(100vh - 74px);
    width: 248px;

    position: absolute;
    left: -248px;
    top: 74px;
    z-index: 999;

    transition: left 250ms;

    @media (max-width: 768px) {
      top: 66px;
    }

    @media (min-width: 993px) and (max-width: 1400px) {
      top: 60px;
    }

    ${() =>
      isMobile &&
      css`
        width: 100%;

        left: -100%;
      `}
  `}
`

export const BackDrop = styled.div`
  &.visible {
    ${Container} {
      left: 0;
    }
  }
`

export const Item = styled.div<ItemProps>`
  ${props => css`
    border-bottom: 1px solid rgba(147, 152, 171, 0.16);
    cursor: pointer;
    color: ${props.theme.colors.global.content[700]};
    font-size: ${props.theme.font.size[16]};
    line-height: ${props.theme.line.height[150]};
    padding: 12px 16px;

    height: 48px;
    width: 100%;

    ${props.active
      ? css`
          background-color: ${rgba(
            props.theme.colors.global.content[300],
            0.64
          )};
        `
      : css`
          &:hover {
            background-color: ${rgba(
              props.theme.colors.global.content[300],
              0.64
            )};
          }
        `}
  `}
`
