import { createGlobalStyle, css } from 'styled-components'

import '@raiadrogasil/pulso-icons/pulsodesignsystem/style.css'

export default createGlobalStyle`
  ${props => css`
    .pulso-text-field {
      input {
        min-width: auto !important;
      }
    }

    .pulso-link {
      span {
        display: none;
      }
    }

    .pulso-button[disabled] {
      cursor: not-allowed;
    }

    .pulso-radio {
      .pulso-radio__checkmark {
        margin-right: 12px !important;
      }

      .pulso-radio__label-text {
        color: ${props.theme.colors.global.content[500]};
        font-size: ${props.theme.font.size[16]};
        font-weight: ${props.theme.font.weight[600]};
        line-height: ${props.theme.line.height[150]} !important;
      }
    }

    .pulso-checkbox {
      .pulso-checkbox__checkmark {
        margin-right: 12px !important;

        &::after {
          top: 0px !important;
          left: 3px !important;
        }
      }

      .pulso-checkbox__label-text {
        color: ${props.theme.colors.global.content[500]} !important;
        font-size: ${props.theme.font.size[16]};
        font-weight: ${props.theme.font.weight[600]};
        line-height: ${props.theme.line.height[150]} !important;
      }
    }
  `}
`
