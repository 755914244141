import { ButtonHTMLAttributes, useCallback } from 'react'

import { useAnalyticsContext } from 'contexts/Analytics'

import { normalizeString } from 'utils/masks'

import { Container } from './styles'

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  label?: string
  size?: 'sm' | 'md'
  styles:
    | 'primary'
    | 'secondary'
    | 'black-secondary'
    | 'tertiary'
    | 'ghost'
    | 'black-ghost'
    | 'icon-only'
    | 'black-icon-only'
  display?: 'block'
  leadingIcon?: string
  trailingIcon?: string
  dataTestId?: string
}

export function Button({
  label,
  size,
  styles,
  display,
  leadingIcon,
  trailingIcon,
  dataTestId,
  type,
  name,
  disabled,
  onClick
}: Props) {
  const { category, currentPage, setEvent } = useAnalyticsContext()

  const sanitizedLabel = normalizeString(name || label || 'no-label')
  const dataCurrentPage = normalizeString(`${category}_${currentPage}`)
  const dataCollect = `button_${dataCurrentPage}_${sanitizedLabel}`
  const dataCollectInfo = `${dataCurrentPage}|clicou|${sanitizedLabel}`

  const handleOnClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      onClick && onClick(event)

      setEvent &&
        setEvent({
          eventAction: 'clicou',
          eventLabel: sanitizedLabel
        })
    },
    [onClick, setEvent, sanitizedLabel]
  )

  return (
    <Container
      size={size || 'md'}
      styles={styles}
      display={display}
      type={type || 'button'}
      title=""
      name={name}
      disabled={disabled}
      data-testid={dataTestId}
      data-collect={dataCollect}
      data-collect-info={dataCollectInfo}
      onClick={handleOnClick}
    >
      {leadingIcon && <i className={leadingIcon} />}

      {label && styles !== 'icon-only' && <span>{label}</span>}

      {trailingIcon && <i className={trailingIcon} />}
    </Container>
  )
}

Button.defaultProps = {
  label: undefined,
  size: 'md',
  display: undefined,
  leadingIcon: undefined,
  trailingIcon: undefined,
  dataTestId: undefined
}
