import styled, { css } from 'styled-components'

import { rgba } from 'polished'

interface Props {
  size: 'sm' | 'md'
  styles:
    | 'primary'
    | 'secondary'
    | 'black-secondary'
    | 'tertiary'
    | 'ghost'
    | 'black-ghost'
    | 'icon-only'
    | 'black-icon-only'
  display?: 'block'
}

export const Container = styled.button<Props>`
  ${props => css`
    background-color: transparent;
    border-color: transparent;
    border-radius: 8px;
    border-style: solid;
    border-width: 1px;
    box-shadow: unset;
    opacity: 1;
    padding: 14px 16px;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;

    transition-duration: 250ms;
    transition-property: background-color, color;
    transition-timing-function: cubic-bezier(0.37, 0, 0.63, 1);

    &:focus:not([disabled]),
    :focus-visible:not([disabled]) {
      outline-color: ${props.theme.colors.global.background.black};
      outline-offset: 2px;
      outline-style: solid;
      outline-width: 2px;
    }

    &:disabled {
      cursor: not-allowed;
      opacity: 0.48;
    }

    span {
      font-size: ${props.theme.font.size[14]};
      font-weight: ${props.theme.font.weight[700]};
      line-height: ${props.theme.line.height[150]};
    }

    ${props.display === 'block' &&
    css`
      width: 100%;
    `}

    ${props.size === 'sm' &&
    css`
      height: 40px;

      i {
        font-size: ${props.theme.font.size[21]} !important;
      }
    `}

    ${props.size === 'md' &&
    css`
      height: 48px;

      i {
        font-size: ${props.theme.font.size[24]} !important;
      }
    `}

    ${props.styles === 'primary' &&
    css`
      background-color: ${props.theme.colors.brand[500]};
      color: ${props.theme.colors.global.background.white};

      &:hover:not([disabled]) {
        background-color: ${props.theme.colors.brand[700]};
      }

      &:focus:not([disabled]),
      :focus-visible:not([disabled]) {
        background-color: ${props.theme.colors.brand[700]};
      }

      &:active:not([disabled]) {
        background-color: ${props.theme.colors.brand[900]};
      }

      &:disabled {
        background-color: ${props.theme.colors.global.background[400]};
      }
    `}

    ${props.styles === 'secondary' &&
    css`
      border-color: ${props.theme.colors.brand[500]};
      color: ${props.theme.colors.brand[500]};

      &:hover:not([disabled]) {
        background-color: ${rgba(props.theme.colors.brand[500], 0.08)};
        border-color: ${props.theme.colors.brand[700]};
        color: ${props.theme.colors.brand[700]};
      }

      &:focus:not([disabled]),
      :focus-visible:not([disabled]) {
        background-color: ${rgba(props.theme.colors.brand[500], 0.08)};
        border-color: ${props.theme.colors.brand[700]};
        color: ${props.theme.colors.brand[700]};
      }

      &:active:not([disabled]) {
        background-color: ${rgba(props.theme.colors.brand[500], 0.16)};
        border-color: ${props.theme.colors.brand[900]};
        color: ${props.theme.colors.brand[900]};
      }

      &:disabled {
        background-color: transparent;
        border-color: ${props.theme.colors.global.background[400]};
        color: ${props.theme.colors.global.background[400]};
      }
    `}

    ${props.styles === 'black-secondary' &&
    css`
      border-color: ${props.theme.colors.global.background.black};
      color: ${props.theme.colors.global.background.black};

      &:hover:not([disabled]) {
        background-color: ${rgba(
          props.theme.colors.global.background.black,
          0.08
        )};
        border-color: ${props.theme.colors.global.background.black};
        color: ${props.theme.colors.global.background.black};
      }

      &:focus:not([disabled]),
      :focus-visible:not([disabled]) {
        background-color: ${rgba(
          props.theme.colors.global.background.black,
          0.08
        )};
        border-color: ${props.theme.colors.global.background.black};
        color: ${props.theme.colors.global.background.black};
      }

      &:active:not([disabled]) {
        background-color: ${rgba(
          props.theme.colors.global.background.black,
          0.16
        )};
        border-color: ${props.theme.colors.global.background.black};
        color: ${props.theme.colors.global.background.black};
      }

      &:disabled {
        background-color: transparent;
        border-color: ${props.theme.colors.global.background[400]};
        color: ${props.theme.colors.global.background[400]};
      }
    `}

    ${props.styles === 'tertiary' &&
    css`
      background-color: transparent;
      color: ${props.theme.colors.brand[500]};

      &:hover:not([disabled]) {
        background-color: ${rgba(props.theme.colors.brand[500], 0.08)};
        color: ${props.theme.colors.brand[700]};
      }

      &:focus:not([disabled]),
      :focus-visible:not([disabled]) {
        background-color: ${rgba(props.theme.colors.brand[500], 0.08)};
        color: ${props.theme.colors.brand[700]};
      }

      &:active:not([disabled]) {
        background-color: ${rgba(props.theme.colors.brand[500], 0.16)};
        color: ${props.theme.colors.brand[900]};
      }
    `}

    ${props.styles === 'ghost' &&
    css`
      border-color: transparent;
      color: ${props.theme.colors.brand[500]};

      &:hover:not([disabled]) {
        color: ${props.theme.colors.brand[700]};
      }

      &:focus:not([disabled]),
      :focus-visible:not([disabled]) {
        color: ${props.theme.colors.brand[700]};
      }

      &:active:not([disabled]) {
        color: ${props.theme.colors.brand[700]};
      }

      &:disabled {
        color: ${props.theme.colors.global.background[400]};
      }
    `}

    ${props.styles === 'black-ghost' &&
    css`
      border-color: transparent;
      color: ${props.theme.colors.global.content[900]};

      &:hover:not([disabled]) {
        color: ${props.theme.colors.global.content[700]};
      }

      &:focus:not([disabled]),
      :focus-visible:not([disabled]) {
        color: ${props.theme.colors.global.content[700]};
      }

      &:active:not([disabled]) {
        color: ${props.theme.colors.global.content[700]};
      }

      &:disabled {
        color: ${props.theme.colors.global.background[400]};
      }
    `}

    ${props.styles === 'icon-only' &&
    css`
      color: ${props.theme.colors.brand[500]};
      width: fit-content;

      &:hover:not([disabled]) {
        background-color: ${rgba(props.theme.colors.brand[500], 0.08)};
        color: ${props.theme.colors.brand[700]};
      }

      &:focus:not([disabled]),
      :focus-visible:not([disabled]) {
        background-color: ${rgba(props.theme.colors.brand[500], 0.08)};
        color: ${props.theme.colors.brand[700]};
      }

      &:active:not([disabled]) {
        background-color: ${rgba(props.theme.colors.brand[500], 0.16)};
        color: ${props.theme.colors.brand[900]};
      }
    `}

    ${props.styles === 'black-icon-only' &&
    css`
      color: ${props.theme.colors.global.content[900]};
      width: fit-content;

      &:hover:not([disabled]) {
        color: ${props.theme.colors.global.content[700]};
      }

      &:focus:not([disabled]),
      :focus-visible:not([disabled]) {
        color: ${props.theme.colors.global.content[700]};
      }

      &:active:not([disabled]) {
        color: ${props.theme.colors.global.content[700]};
      }
    `}
  `}
`
