import { createGlobalStyle } from 'styled-components'

export default createGlobalStyle`
  @font-face {
    font-family: 'Open Sans';
    src: url('/fonts/OpenSans/OpenSans-Regular.ttf');
    font-display: swap;
    font-style: normal;
    font-weight: 400;
  }

  @font-face {
    font-family: 'Open Sans';
    src: url('/fonts/OpenSans/OpenSans-Medium.ttf');
    font-display: swap;
    font-style: normal;
    font-weight: 500;
  }

  @font-face {
    font-family: 'Open Sans';
    src: url('/fonts/OpenSans/OpenSans-SemiBold.ttf');
    font-display: swap;
    font-style: normal;
    font-weight: 600;
  }

  @font-face {
    font-family: 'Open Sans';
    src: url('/fonts/OpenSans/OpenSans-Bold.ttf');
    font-display: swap;
    font-style: normal;
    font-weight: 700;
  }
`
