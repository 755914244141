import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState
} from 'react'

import {
  ISeller,
  ICodeValidation,
  IRecoverPassword
} from 'pages/RecoverPassword/interface'

import {
  getSessionStorageItem,
  setSessionStorageItem,
  removeSessionStorageItem
} from 'utils/storage/session'

interface RecoverPasswordContextData {
  step: number | null
  registration: IRecoverPassword | null
  previousStep: () => void
  nextStep: () => void
  initJourney: () => void
  resetJourney: () => void
  setSellerData: (data: ISeller) => void
  setCodeValidationData: (data: ICodeValidation) => void
}

const Context = createContext<RecoverPasswordContextData>(
  {} as RecoverPasswordContextData
)

interface Props {
  children: ReactNode
}

function RecoverPasswordContext({ children }: Props) {
  const [step, setStep] = useState<number | null>(() => {
    const storagedStep = getSessionStorageItem(
      'RD@Onboarding:RecoverPassword:step'
    )

    return storagedStep ? Number(storagedStep) : 0
  })

  const [registration, setRegistration] = useState<IRecoverPassword | null>(
    () => {
      const storagedRegistration = getSessionStorageItem(
        'RD@Onboarding:RecoverPassword:registration'
      )

      return storagedRegistration || null
    }
  )

  const clearData = useCallback(() => {
    removeSessionStorageItem('RD@Onboarding:RecoverPassword:step')
    removeSessionStorageItem('RD@Onboarding:RecoverPassword:registration')

    setStep(null)
  }, [])

  const initJourney = useCallback(() => {
    removeSessionStorageItem('RD@Onboarding:RecoverPassword:step')
    removeSessionStorageItem('RD@Onboarding:RecoverPassword:registration')

    setStep(0)

    setSessionStorageItem('RD@Onboarding:RecoverPassword:step', 0)
  }, [])

  const resetJourney = useCallback(() => {
    clearData()
  }, [clearData])

  const previousStep = useCallback(() => {
    setStep(currentStep => {
      if (currentStep && currentStep >= 2) {
        setSessionStorageItem(
          'RD@Onboarding:RecoverPassword:step',
          currentStep - 1
        )

        return currentStep - 1
      }

      initJourney()

      return null
    })
  }, [initJourney])

  const nextStep = useCallback(() => {
    setStep(currentStep => {
      if (currentStep) {
        setSessionStorageItem(
          'RD@Onboarding:RecoverPassword:step',
          currentStep + 1
        )

        return currentStep + 1
      }

      setSessionStorageItem('RD@Onboarding:RecoverPassword:step', '1')

      return 1
    })
  }, [])

  const setSellerData = useCallback((data: ISeller) => {
    const seller = {
      seller: data,
      codeValidation: null
    }

    setRegistration(seller)

    setSessionStorageItem('RD@Onboarding:RecoverPassword:registration', seller)
  }, [])

  const setCodeValidationData = useCallback(
    (data: ICodeValidation) => {
      if (registration) {
        setSessionStorageItem('RD@Onboarding:RecoverPassword:registration', {
          ...registration,
          codeValidation: data
        })

        setRegistration({ ...registration, codeValidation: data })
      }
    },
    [registration]
  )

  const providerValue = useMemo(
    () => ({
      step,
      registration,
      previousStep,
      nextStep,
      initJourney,
      resetJourney,
      setSellerData,
      setCodeValidationData
    }),
    [
      step,
      registration,
      previousStep,
      nextStep,
      initJourney,
      resetJourney,
      setSellerData,
      setCodeValidationData
    ]
  )

  return (
    <Context.Provider value={providerValue}>
      <div data-testid="recover-password-context-provider">{children}</div>
    </Context.Provider>
  )
}

function useRecoverPasswordContext(): RecoverPasswordContextData {
  return useContext(Context)
}

export { RecoverPasswordContext, useRecoverPasswordContext }
