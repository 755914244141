import { useEffect, useCallback } from 'react'

import { useNavigate } from 'react-router-dom'

import { Container, Row, Col } from 'react-bootstrap'

import ErrorImage from 'assets/images/not-found.png'

import { TITLE_NOT_FOUND } from 'constants/title'
import { ROUTE_CATEGORIES } from 'constants/routes'
import { PAGEVIEW_ERROR_NOT_FOUND } from 'constants/analytics'

import { useAnalyticsContext } from 'contexts/Analytics'

import { Heading } from 'components/Typography/Heading'
import { Paragraph } from 'components/Typography/Paragraph'
import { Button } from 'components/DesignSystem/Button'

export function NotFoundError() {
  const navigate = useNavigate()

  const { setPageView } = useAnalyticsContext()

  useEffect(() => {
    document.title = TITLE_NOT_FOUND
  }, [])

  useEffect(() => {
    setPageView({
      virtualPageview: PAGEVIEW_ERROR_NOT_FOUND
    })
  }, [setPageView])

  const handleOnClick = useCallback(() => {
    navigate(ROUTE_CATEGORIES)
  }, [navigate])

  return (
    <Container>
      <Row className="justify-content-center text-center">
        <Col sm={9} md={7} lg={6} xl={7}>
          <Row className="my-4">
            <Col>
              <img
                src={ErrorImage}
                alt="Página não encontrada. A página que você estava tentando exibir não existe. Volte para a página inicial."
                className="img-fluid"
              />
            </Col>
          </Row>

          <Row className="mb-4">
            <Col>
              <Heading type="huge">Página não encontrada</Heading>
            </Col>
          </Row>

          <Row className="mb-5">
            <Col>
              <Paragraph>
                A página que você estava tentando exibir não existe, volte para
                página inicial.
              </Paragraph>
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col xs={12} xl={7}>
              <Button
                label="Voltar para página inicial"
                styles="tertiary"
                display="block"
                dataTestId="voltar"
                onClick={handleOnClick}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  )
}
