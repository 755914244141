import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'

import { useLocation } from 'react-router-dom'

import {
  ROUTE_SECOND_JOURNEY,
  ROUTE_EDIT_BILLING_ADDRESS,
  ROUTE_EDIT_COMMERCIAL_DATA,
  ROUTE_EDIT_CONTRACT
} from 'constants/routes'

import { useSecondJourneyContext } from 'contexts/Journey/Second'

import { SideMenu } from 'components/Layout/SideMenu'

interface SideMenuContextData {
  menuVisible: boolean
  buttonVisible: boolean
  showSideMenu: () => void
  hideSideMenu: () => void
  toggleSideMenu: () => void
}

const Context = createContext<SideMenuContextData>({} as SideMenuContextData)

interface Props {
  children: ReactNode
}

function SideMenuContext({ children }: Props) {
  const location = useLocation()

  const { step } = useSecondJourneyContext()

  const [offset, setOffset] = useState(0)

  const [menuVisible, setMenuVisible] = useState(false)
  const [buttonVisible, setButtonVisible] = useState(false)

  useEffect(() => {
    const onScroll = () => setOffset(window.pageYOffset)

    window.removeEventListener('scroll', onScroll)
    window.addEventListener('scroll', onScroll, { passive: true })

    return () => window.removeEventListener('scroll', onScroll)
  }, [])

  useEffect(() => {
    if (offset > 0) {
      setMenuVisible(false)
    }
  }, [offset])

  useEffect(() => {
    if (location.pathname === ROUTE_SECOND_JOURNEY) {
      switch (step) {
        case 1:
        case 2:
        case 3:
        case 4:
        case 5:
          setButtonVisible(true)
          break

        default:
          setButtonVisible(false)
          break
      }
    } else {
      switch (location.pathname) {
        case ROUTE_EDIT_COMMERCIAL_DATA:
        case ROUTE_EDIT_BILLING_ADDRESS:
        case ROUTE_EDIT_CONTRACT:
          setButtonVisible(true)
          break

        default:
          setButtonVisible(false)
          break
      }
    }
  }, [location, step])

  const showSideMenu = useCallback(() => {
    setMenuVisible(true)
  }, [])

  const hideSideMenu = useCallback(() => {
    setMenuVisible(false)
  }, [])

  const toggleSideMenu = useCallback(() => {
    setMenuVisible(currentVisible => !currentVisible)
  }, [])

  const providerValue = useMemo(
    () => ({
      menuVisible,
      buttonVisible,
      showSideMenu,
      hideSideMenu,
      toggleSideMenu
    }),
    [menuVisible, buttonVisible, showSideMenu, hideSideMenu, toggleSideMenu]
  )

  return (
    <Context.Provider value={providerValue}>
      <div data-testid="side-menu-context-provider">
        <SideMenu visible={menuVisible} />

        {children}
      </div>
    </Context.Provider>
  )
}

function useSideMenuContext(): SideMenuContextData {
  return useContext(Context)
}

export { SideMenuContext, useSideMenuContext }
