import styled, { css } from 'styled-components'

export const Typography = styled.div`
  ${props => css`
    color: ${props.theme.colors.global.content[500]};
    font-size: ${props.theme.font.size[16]};
    font-weight: ${props.theme.font.weight[400]};
    line-height: ${props.theme.line.height[150]};

    @media (max-width: 767px) {
      font-size: ${props.theme.font.size[14]};
    }
  `}
`
