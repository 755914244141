import { BrowserRouter } from 'react-router-dom'

import { Routing } from 'routes/Routing'

import { Contexts } from 'contexts'

import { Styles } from 'styles'

export function App() {
  return (
    <BrowserRouter>
      <Contexts>
        <Routing />

        <Styles />
      </Contexts>
    </BrowserRouter>
  )
}
