import { tokens } from './tokens'

export const theme = {
  colors: {
    brand: {
      100: tokens.brand_100,
      200: tokens.brand_200,
      500: tokens.brand_500,
      700: tokens.brand_700,
      900: tokens.brand_900
    },
    global: {
      background: {
        100: tokens.global_background_100,
        400: tokens.global_background_400,
        600: tokens.global_background_600,
        800: tokens.global_background_800,
        black: tokens.global_background_black,
        white: tokens.global_background_white
      },
      content: {
        300: tokens.global_content_300,
        400: tokens.global_content_400,
        500: tokens.global_content_500,
        700: tokens.global_content_700,
        900: tokens.global_content_900
      },
      feedback: {
        positive: {
          100: tokens.global_feedback_positive_100,
          500: tokens.global_feedback_positive_500
        },
        informative: {
          100: tokens.global_feedback_informative_100,
          500: tokens.global_feedback_informative_500
        },
        negative: {
          500: tokens.global_feedback_negative_500
        },
        warning: {
          500: tokens.global_feedback_warning_500
        },
        highlight: {
          500: tokens.global_feedback_highlight_500
        }
      }
    }
  },
  font: {
    family: tokens.font_family,
    weight: {
      300: tokens.font_weight_300,
      400: tokens.font_weight_400,
      500: tokens.font_weight_500,
      600: tokens.font_weight_600,
      700: tokens.font_weight_700
    },
    size: {
      10: tokens.font_size_10,
      12: tokens.font_size_12,
      14: tokens.font_size_14,
      16: tokens.font_size_16,
      18: tokens.font_size_18,
      21: tokens.font_size_21,
      24: tokens.font_size_24,
      28: tokens.font_size_28,
      32: tokens.font_size_32,
      36: tokens.font_size_36,
      44: tokens.font_size_44,
      48: tokens.font_size_48,
      60: tokens.font_size_60
    }
  },
  line: {
    height: {
      100: tokens.line_height_100,
      125: tokens.line_height_125,
      150: tokens.line_height_150,
      175: tokens.line_height_175,
      200: tokens.line_height_200
    }
  }
}
