import { useLayoutEffect } from 'react'

import ReactDOM from 'react-dom'

export function BodyPortal({ children }: any) {
  const domNode =
    document.querySelector('#body-portal') || document.createElement('div')
  domNode.setAttribute('id', 'body-portal')
  document.body.appendChild(domNode)

  useLayoutEffect(() => {
    async function callback() {
      const openedModals =
        document.getElementsByClassName('dialog-modal').length

      openedModals > 0
        ? document.body.classList.add('no-overflow')
        : document.body.classList.remove('no-overflow')
    }

    const targetNode = document.getElementById('body-portal') as HTMLElement

    const config = { attributes: true, childList: true, subtree: true }

    const observer = new MutationObserver(callback)

    observer.observe(targetNode, config)
  }, [])

  return ReactDOM.createPortal(children, domNode)
}
