import { lazy } from 'react'

import {
  // First Journey
  ROUTE_CATEGORIES,
  ROUTE_FIRST_JOURNEY,

  // Auth
  ROUTE_LOGIN,
  ROUTE_RECOVER_PASSWORD,

  // Second Journey
  ROUTE_SECOND_JOURNEY,
  ROUTE_EDIT_COMMERCIAL_DATA,
  ROUTE_EDIT_BILLING_ADDRESS,
  ROUTE_EDIT_CONTRACT,

  // Admin
  ROUTE_ADMIN_HUNTERS_LIST,
  ROUTE_ADMIN_CREATE_HUNTER,
  ROUTE_ADMIN_HUNTER_PROFILE,

  // Hunter
  ROUTE_HUNTER_SELLERS_LIST,
  ROUTE_HUNTER_SELLER_CONTRACT,

  // Errors
  ROUTE_NOT_FOUND_ERROR,
  ROUTE_GENERIC_ERROR,
  ROUTE_UNEXPECTED_ERROR,
  ROUTE_OFFLINE_ERROR,
  ROUTE_FIRST_SESSION_EXPIRED,
  ROUTE_SECOND_SESSION_EXPIRED
} from 'constants/routes'

import { RolesEnum } from 'enums/User'

import { IRouteProps } from 'routes/interface'

// First Journey
const Categories = lazy(() =>
  import('pages/Categories').then(module => ({
    default: module.Categories
  }))
)

const FirstJourney = lazy(() =>
  import('pages/Journey/First').then(module => ({
    default: module.FirstJourney
  }))
)

// Auth
const Login = lazy(() =>
  import('pages/Login').then(module => ({
    default: module.Login
  }))
)

const RecoverPassword = lazy(() =>
  import('pages/RecoverPassword').then(module => ({
    default: module.RecoverPassword
  }))
)

// Second Journey
const SecondJourney = lazy(() =>
  import('pages/Journey/Second').then(module => ({
    default: module.SecondJourney
  }))
)

const EditingCommercialData = lazy(() =>
  import('pages/Journey/Editing/CommercialData').then(module => ({
    default: module.CommercialData
  }))
)

const EditingBillingAddress = lazy(() =>
  import('pages/Journey/Editing/BillingAddress').then(module => ({
    default: module.BillingAddress
  }))
)

const EditingContract = lazy(() =>
  import('pages/Journey/Editing/Contract').then(module => ({
    default: module.Contract
  }))
)

// Admin
const HuntersList = lazy(() =>
  import('pages/Admin/HuntersList').then(module => ({
    default: module.HuntersList
  }))
)

const CreateHunter = lazy(() =>
  import('pages/Admin/CreateHunter').then(module => ({
    default: module.CreateHunter
  }))
)

const HunterProfile = lazy(() =>
  import('pages/Admin/HunterProfile').then(module => ({
    default: module.HunterProfile
  }))
)

// Hunter
const SellersList = lazy(() =>
  import('pages/Hunter/SellersList').then(module => ({
    default: module.SellersList
  }))
)

const SellerContract = lazy(() =>
  import('pages/Hunter/SellerContract').then(module => ({
    default: module.SellerContract
  }))
)

// Errors
const NotFoundError = lazy(async () => {
  await new Promise(resolve => {
    setTimeout(resolve, 250)
  })

  return import('pages/Errors/NotFound').then(module => ({
    default: module.NotFoundError
  }))
})

const GenericError = lazy(async () => {
  await new Promise(resolve => {
    setTimeout(resolve, 250)
  })

  return import('pages/Errors/Generic').then(module => ({
    default: module.GenericError
  }))
})

const UnexpectedError = lazy(async () => {
  await new Promise(resolve => {
    setTimeout(resolve, 250)
  })

  return import('pages/Errors/Unexpected').then(module => ({
    default: module.UnexpectedError
  }))
})

const OfflineError = lazy(async () => {
  await new Promise(resolve => {
    setTimeout(resolve, 250)
  })

  return import('pages/Errors/Offline').then(module => ({
    default: module.OfflineError
  }))
})

const FirstJourneySessionExpired = lazy(async () => {
  await new Promise(resolve => {
    setTimeout(resolve, 250)
  })

  return import('pages/Errors/SessionExpired/FirstJourney').then(module => ({
    default: module.FirstJourneySessionExpired
  }))
})

const SecondJourneySessionExpired = lazy(async () => {
  await new Promise(resolve => {
    setTimeout(resolve, 250)
  })

  return import('pages/Errors/SessionExpired/SecondJourney').then(module => ({
    default: module.SecondJourneySessionExpired
  }))
})

const routes: IRouteProps[] = [
  // First Journey
  {
    path: ROUTE_CATEGORIES,
    component: Categories,
    isPrivate: false
  },
  {
    path: ROUTE_FIRST_JOURNEY,
    component: FirstJourney,
    isPrivate: false
  },

  // Auth
  {
    path: ROUTE_LOGIN,
    component: Login,
    isPrivate: false
  },
  {
    path: ROUTE_RECOVER_PASSWORD,
    component: RecoverPassword,
    isPrivate: false
  },

  // Second Journey
  {
    path: ROUTE_SECOND_JOURNEY,
    component: SecondJourney,
    isPrivate: true,
    role: RolesEnum.SELLER
  },
  {
    path: ROUTE_EDIT_COMMERCIAL_DATA,
    component: EditingCommercialData,
    isPrivate: true,
    role: RolesEnum.SELLER
  },
  {
    path: ROUTE_EDIT_BILLING_ADDRESS,
    component: EditingBillingAddress,
    isPrivate: true,
    role: RolesEnum.SELLER
  },
  {
    path: ROUTE_EDIT_CONTRACT,
    component: EditingContract,
    isPrivate: true,
    role: RolesEnum.SELLER
  },

  // Admin
  {
    path: ROUTE_ADMIN_HUNTERS_LIST,
    component: HuntersList,
    isPrivate: true,
    role: RolesEnum.ADMIN
  },
  {
    path: ROUTE_ADMIN_CREATE_HUNTER,
    component: CreateHunter,
    isPrivate: true,
    role: RolesEnum.ADMIN
  },
  {
    path: `${ROUTE_ADMIN_HUNTER_PROFILE}/:id`,
    component: HunterProfile,
    isPrivate: true,
    role: RolesEnum.ADMIN
  },

  // Hunter
  {
    path: ROUTE_HUNTER_SELLERS_LIST,
    component: SellersList,
    isPrivate: true,
    role: RolesEnum.HUNTER
  },
  {
    path: `${ROUTE_HUNTER_SELLER_CONTRACT}/:id`,
    component: SellerContract,
    isPrivate: true,
    role: RolesEnum.HUNTER
  },

  // Errors
  {
    path: ROUTE_NOT_FOUND_ERROR,
    component: NotFoundError,
    isPrivate: false
  },
  {
    path: ROUTE_GENERIC_ERROR,
    component: GenericError,
    isPrivate: false
  },
  {
    path: ROUTE_UNEXPECTED_ERROR,
    component: UnexpectedError,
    isPrivate: false
  },
  {
    path: ROUTE_OFFLINE_ERROR,
    component: OfflineError,
    isPrivate: false
  },
  {
    path: ROUTE_FIRST_SESSION_EXPIRED,
    component: FirstJourneySessionExpired,
    isPrivate: false
  },
  {
    path: ROUTE_SECOND_SESSION_EXPIRED,
    component: SecondJourneySessionExpired,
    isPrivate: false,
    role: RolesEnum.SELLER
  }
]

export default routes
