export function cnpjMask(value: string): string {
  return value
    .replace(/\D+/g, '')
    .replace(/(\d{2})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1/$2')
    .replace(/(\d{4})(\d)/, '$1-$2')
    .replace(/(-\d{2})\d+$/, '$1')
}

export function fullNameMask(value: string): string {
  return value.replace(/[^a-zA-ZáéíóúàèìòùâêîôûãõçÁÉÍÓÚÀÈÌÒÙÂÊÎÔÛÃÕÇ ']/g, '')
}

export function phoneMask(value: string): string {
  return value
    .replace(/\D/g, '')
    .replace(/^(\d{2})(\d)/g, '($1) $2')
    .replace(/(\d)(\d{4})$/, '$1-$2')
}

export function removeMask(value: string | undefined): string {
  if (!value) return ''

  return value.replace(/[\D]/g, '')
}

export function cepMask(value: string): string {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '$1$2')
    .replace(/(\d)(\d{3})$/, '$1-$2')
}

export function numbersMask(value: string): string {
  return value.replace(/\D/g, '')
}

export function alphanumericMask(value: string): string {
  return value.replace(/[^0-9a-z]/gi, '').toLocaleLowerCase()
}

export function normalizeString(value: string): string {
  return value
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/([^a-z0-9\\/_\s-])/gi, '')
    .replace(/(\s+)/g, '-')
    .replace(/\-\-+/g, '-')
    .replace(/(^-+|-+$)/g, '')
}
