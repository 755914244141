import { Row, Col } from 'react-bootstrap'

import { BodyPortal } from 'components/BodyPortal'
import { Backdrop } from 'components/Backdrop'

import { Wrapper, Container, Title, CloseButton } from './styles'

interface Props {
  title: string
  children: React.ReactNode
  onClose: () => void
}

export function Modal({ title, children, onClose }: Props) {
  return (
    <BodyPortal>
      <Backdrop className="dialog-modal">
        <Wrapper>
          <Container data-testid="modal">
            <Row className="justify-content-between">
              <Col xs={10}>
                <Title>{title}</Title>
              </Col>

              <Col xs={2} sm={1}>
                <CloseButton
                  data-testid="close-modal"
                  onClick={() => onClose()}
                >
                  <i className="rdicon-dismiss" />
                </CloseButton>
              </Col>
            </Row>

            {children}
          </Container>
        </Wrapper>
      </Backdrop>
    </BodyPortal>
  )
}
