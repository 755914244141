import styled, { css } from 'styled-components'

import { rgba } from 'polished'

interface Props {
  hasMessages: boolean
}

export const Container = styled.div<Props>`
  ${props => css`
    background-color: ${rgba(props.theme.colors.global.background.white, 0.75)};

    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column;

    height: 100vh;
    width: 100vw;

    overflow: hidden;
    z-index: 998;

    left: 0;
    top: 0;
    position: fixed;

    ${!props.hasMessages &&
    css`
      display: none;
    `}
  `}
`
