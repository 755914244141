import styled, { css } from 'styled-components'

import { isDesktop } from 'react-device-detect'

interface Props {
  bgColor: string
}

export const Container = styled.header<Props>`
  height: 74px;
  margin-bottom: ${!isDesktop ? '24px' : '40px'};
  padding: 13px 24px;
  position: relative;
  z-index: 1010;

  @media (max-width: 768px) {
    height: 66px;
    margin-bottom: 8px;
  }

  @media (min-width: 993px) and (max-width: 1400px) {
    height: 60px;
    margin-bottom: 24px;
  }

  ${props =>
    isDesktop
      ? css`
          background-color: ${props.theme.colors.global.background[100]};
        `
      : css`
          background-color: ${props.bgColor};
        `}
`
