import styled, { css } from 'styled-components'

import { animated } from 'react-spring'

import { IToastType } from 'contexts/Toast'

interface Props {
  type: IToastType
  description: string
}

export const Container = styled(animated.div)<Props>`
  ${props => css`
    border-radius: 10px;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);

    display: flex;

    position: relative;
    padding: 16px 8px 16px 16px;

    width: 360px;

    & + div {
      margin-top: 8px;
    }

    ${props.type === 'success' &&
    css`
      background-color: ${props.theme.colors.global.feedback.positive[500]};
      color: ${props.theme.colors.global.background.white};
    `}

    ${props.type === 'info' &&
    css`
      background-color: ${props.theme.colors.global.feedback.informative[500]};
      color: ${props.theme.colors.global.background.white};
    `}

  ${props.type === 'warning' &&
    css`
      background-color: ${props.theme.colors.global.feedback.warning[500]};
      color: ${props.theme.colors.global.content[900]};
    `}

  ${props.type === 'error' &&
    css`
      background-color: ${props.theme.colors.global.feedback.negative[500]};
      color: ${props.theme.colors.global.background.white};
    `}

  > i {
      font-size: ${props.theme.font.size[24]};
      margin: auto 12px auto 0;
    }

    div {
      flex: 1;
      padding-right: 8px;

      strong {
        font-size: ${props.theme.font.size[14]};
        font-weight: ${props.theme.font.weight[700]};
        line-height: ${props.theme.line.height[150]};
      }

      p {
        font-size: ${props.theme.font.size[14]};
        line-height: ${props.theme.line.height[150]};
      }
    }

    button {
      background-color: transparent;
      border: 0;

      color: inherit;
      font-size: ${props.theme.font.size[21]};

      margin: auto 0;

      width: 40px;
      height: 40px;

      &.outline {
        border-color: inherit;
        border-radius: 4px;
        border-style: solid;
        border-width: 1px;

        font-size: ${props.theme.font.size[14]};
        font-weight: ${props.theme.font.weight[700]};

        margin: auto 8px auto 0;
      }
    }

    ${props.description.length === 0 &&
    css`
      align-items: center;

      svg {
        margin-top: 0;
      }
    `}
  `}
`
