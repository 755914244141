import {
  createContext,
  ReactNode,
  useContext,
  useState,
  useCallback,
  useMemo
} from 'react'

import {
  IConfirmationDialog,
  Dialog
} from 'contexts/DialogConfirmation/components/Dialog'

interface DialogConfirmationContextData {
  confirmationDialog: IConfirmationDialog | undefined
  addConfirmation: (confirmation: IConfirmationDialog) => void
  removeConfirmation: () => void
}

const Context = createContext<DialogConfirmationContextData>(
  {} as DialogConfirmationContextData
)

interface Props {
  children: ReactNode
}

function DialogConfirmationContext({ children }: Props) {
  const [confirmationDialog, setConfirmationDialog] = useState<
    IConfirmationDialog | undefined
  >(undefined)

  const addConfirmation = useCallback(
    (confirmationDialog: IConfirmationDialog) => {
      setConfirmationDialog(confirmationDialog)
    },
    []
  )

  const removeConfirmation = useCallback(() => {
    setConfirmationDialog(undefined)
  }, [])

  const providerValue = useMemo(
    () => ({
      confirmationDialog,
      addConfirmation,
      removeConfirmation
    }),
    [confirmationDialog, addConfirmation, removeConfirmation]
  )

  return (
    <Context.Provider value={providerValue}>
      <div data-testid="dialog-confirmation-context-provider">
        {children}

        {confirmationDialog ? (
          <Dialog
            title={confirmationDialog.title}
            description={confirmationDialog.description}
            cancelTxt={confirmationDialog.cancelTxt}
            confirmTxt={confirmationDialog.confirmTxt}
            onCancel={confirmationDialog.onCancel}
            onConfirm={confirmationDialog.onConfirm}
          />
        ) : null}
      </div>
    </Context.Provider>
  )
}

function useDialogConfirmationContext(): DialogConfirmationContextData {
  return useContext(Context)
}

export { DialogConfirmationContext, useDialogConfirmationContext }
