import { useState, useEffect, useCallback } from 'react'

import { useNavigate, useLocation } from 'react-router-dom'

import {
  ROUTE_EDIT_COMMERCIAL_DATA,
  ROUTE_EDIT_BILLING_ADDRESS,
  ROUTE_EDIT_CONTRACT,
  ROUTE_SECOND_JOURNEY
} from 'constants/routes'

import { useAuthContext } from 'contexts/Auth'
import { useSideMenuContext } from 'contexts/Layout/SideMenu'
import { useAnalyticsContext } from 'contexts/Analytics'
import { useSecondJourneyContext } from 'contexts/Journey/Second'

import { BackDrop, Container, Item } from './styles'

interface Props {
  visible: boolean
}

export function SideMenu({ visible }: Props) {
  const navigate = useNavigate()

  const location = useLocation()

  const { signOut } = useAuthContext()

  const { hideSideMenu } = useSideMenuContext()

  const { setEvent } = useAnalyticsContext()

  const { step, afterRegistration, jumpToRegistrationStep } =
    useSecondJourneyContext()

  const [showOnlyLogoutButton, setShowOnlyLogoutButton] = useState(false)

  useEffect(() => {
    if (location.pathname === ROUTE_SECOND_JOURNEY) {
      setShowOnlyLogoutButton(step === 1)
    }
  }, [location, step])

  const handleOnClick = useCallback(
    (route: string, label: string) => {
      hideSideMenu()

      setEvent({
        eventAction: 'clicou-menu',
        eventLabel: label
      })

      navigate(route)
    },
    [hideSideMenu, navigate, setEvent]
  )

  const handleLogout = useCallback(() => {
    hideSideMenu()

    setEvent({
      eventAction: 'clicou-menu',
      eventLabel: 'sair'
    })

    signOut()
  }, [hideSideMenu, setEvent, signOut])

  return (
    <BackDrop className={`${visible ? 'visible' : ''}`} data-testid="side-menu">
      <Container>
        {!showOnlyLogoutButton && (
          <div className="mb-5" data-testid="editing-journey-options">
            <Item
              active={location.pathname === ROUTE_EDIT_COMMERCIAL_DATA}
              onClick={() => {
                handleOnClick(ROUTE_EDIT_COMMERCIAL_DATA, 'dados-da-empresa')
              }}
            >
              Dados da empresa
            </Item>

            <Item
              active={location.pathname === ROUTE_EDIT_BILLING_ADDRESS}
              onClick={() => {
                handleOnClick(ROUTE_EDIT_BILLING_ADDRESS, 'endereco')
              }}
            >
              Endereço
            </Item>

            {!afterRegistration?.userSellerData?.hasAdvisor ? (
              <Item
                active={location.pathname === ROUTE_EDIT_CONTRACT}
                data-testid="termo-de-contrato"
                onClick={() => {
                  handleOnClick(ROUTE_EDIT_CONTRACT, 'termo-de-contrato')
                }}
              >
                Termos de contrato
              </Item>
            ) : null}

            <Item
              onClick={() => {
                jumpToRegistrationStep()
                handleOnClick(ROUTE_SECOND_JOURNEY, 'continuar-cadastro')
              }}
            >
              Continuar cadastro
            </Item>
          </div>
        )}

        <Item onClick={handleLogout} data-testid="logout-button">
          Sair do cadastro
        </Item>
      </Container>
    </BackDrop>
  )
}
