const brand = 'Marketplace - Raia Drogasil S.A.'

export const TITLE_HOME = `Cadastre sua loja - ${brand}`
export const TITLE_CATEGORIES = `Nossas categorias - ${brand}`

export const TITLE_FIRST_JOURNEY_START = `Como você chegou até aqui? - ${brand}`
export const TITLE_FIRST_JOURNEY_IDENTIFICATION = `Vamos começar? - ${brand}`
export const TITLE_FIRST_JOURNEY_REGISTRATION_DATA = `Dados de cadastro - ${brand}`
export const TITLE_FIRST_JOURNEY_CODE_VALIDATION = `Código de verificação - ${brand}`
export const TITLE_FIRST_JOURNEY_COMMERCIAL_DATA = `Informações dos dados comerciais - ${brand}`
export const TITLE_FIRST_JOURNEY_BILLING_ADDRESS = `Endereço para faturamento - ${brand}`
export const TITLE_FIRST_JOURNEY_CREATE_PASSWORD = `Criar senha - ${brand}`
export const TITLE_FIRST_JOURNEY_SUCCESS = `Seus dados foram recebidos - ${brand}`

export const TITLE_LOGIN = `Faça login para acessar sua conta - ${brand}`
export const TITLE_RECOVER_PASSWORD = `Redefinir senha - ${brand}`

export const TITLE_SECOND_JOURNEY_START = `Olá, boas-vindas! - ${brand}`
export const TITLE_SECOND_JOURNEY_CONTRACT = `Termos de contrato - ${brand}`
export const TITLE_SECOND_JOURNEY_INTEGRATOR = `Integradora - ${brand}`
export const TITLE_SECOND_JOURNEY_RESPONSIBLE_DATA = `Dados do responsável - ${brand}`
export const TITLE_SECOND_JOURNEY_FINANCIAL_DATA = `Dados financeiros - ${brand}`
export const TITLE_SECOND_JOURNEY_COMPANY_DATA = `Anexar documentos - ${brand}`
export const TITLE_SECOND_JOURNEY_SUCCESS = `Seus dados foram recebidos - ${brand}`

export const TITLE_EDIT_COMMERCIAL_DATA = `Informações dos dados comerciais - ${brand}`
export const TITLE_EDIT_BILLING_ADDRESS = `Endereço para faturamento - ${brand}`
export const TITLE_EDIT_CONTRACT = `Download dos termos de contrato - ${brand}`

export const TITLE_ADMIN_HUNTERS_LIST = `Listagem de hunters - ${brand}`
export const TITLE_ADMIN_CREATE_HUNTER = `Novo perfil de hunter - ${brand}`
export const TITLE_ADMIN_HUNTER_PROFILE = `Perfil do hunter - ${brand}`

export const TITLE_HUNTER_SELLERS_LIST = `Listagem de sellers - ${brand}`
export const TITLE_HUNTER_SELLER_CONTRACT = `Contrato do seller - ${brand}`

export const TITLE_NOT_FOUND = `Página não encontrada - ${brand}`
export const TITLE_GENERIC_ERROR = `Desculpe, algo deu errado - ${brand}`
export const TITLE_UNEXPECTED_ERROR = `Erro inesperado - ${brand}`
export const TITLE_OFFLINE_ERROR = `Sistema fora do ar - ${brand}`
export const TITLE_SESSION_EXPIRED = `Sessão expirada - ${brand}`
