import { useTransition } from 'react-spring'

import { IToastMessage } from 'contexts/Toast'

import { Message } from './Message'

import { Container } from './styles'

interface Props {
  messages: IToastMessage[]
}

export function Toast({ messages }: Props) {
  const transition = useTransition(messages, {
    from: { opacity: '0' },
    enter: { opacity: '1' },
    leave: { opacity: '0' },
    config: { duration: 250 }
  })

  const fragment = transition((style, item, _, key) => {
    return <Message key={key} message={item} style={style} />
  })

  return (
    <Container hasMessages={messages.length > 0} data-testid="toast">
      {fragment}
    </Container>
  )
}
