import { Navigate } from 'react-router-dom'

import { ROUTE_LOGIN, ROUTE_NOT_FOUND_ERROR } from 'constants/routes'

import { useAuthContext } from 'contexts/Auth'

import { RolesEnum } from 'enums/User'

interface Props {
  isPrivate: boolean
  role?: keyof typeof RolesEnum
  children: JSX.Element
}

export function RequireAuth({ isPrivate, role, children }: Props) {
  const { user } = useAuthContext()

  if (!isPrivate) return children
  if (!user || !user?.data?.role) return <Navigate to={ROUTE_LOGIN} replace />
  if (!role) return children
  if (role && user.data.role === role) return children

  return <Navigate to={ROUTE_NOT_FOUND_ERROR} replace />
}

RequireAuth.defaultProps = {
  role: undefined
}
