import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState
} from 'react'

import { v4 } from 'uuid'

import { Toast } from 'contexts/Toast/components/Toast'

import { useAnalyticsContext } from 'contexts/Analytics'

export type IToastType = 'success' | 'info' | 'warning' | 'error'

export interface IToastMessage {
  id: string
  type: IToastType
  title?: string
  description: string
  shouldConfirm?: boolean
  onConfirm?: () => void
}

interface IGraphQLRejection {
  shouldConfirm?: boolean
  onConfirm: () => void
}

interface ToastContextData {
  messages: IToastMessage[]
  addToast: (message: Omit<IToastMessage, 'id'>) => void
  handleApolloError: () => void
  handleUnexpectedBehavior: () => void
  handleGraphQLRejection: (actions?: IGraphQLRejection) => void
  removeToast: (id: string) => void
}

const Context = createContext<ToastContextData>({} as ToastContextData)

interface Props {
  children: ReactNode
}

function ToastContext({ children }: Props) {
  const { setEvent } = useAnalyticsContext()

  const [messages, setMessages] = useState<IToastMessage[]>([])

  const addToast = useCallback(
    async (message: Omit<IToastMessage, 'id'>) => {
      const toast = {
        id: v4(),
        title: message.title,
        description: message.description,
        type: message.type,
        shouldConfirm: message.shouldConfirm,
        onConfirm: message.onConfirm
      }

      setEvent({
        eventAction: `${message.type}`,
        eventLabel: `${message.title}_${message.description}`
      })

      setMessages(state => [...state, toast])
    },
    [setEvent]
  )

  const handleApolloError = useCallback(() => {
    addToast({
      type: 'warning',
      title: 'Erro inesperado',
      description: 'Tente novamente mais tarde'
    })
  }, [addToast])

  const handleUnexpectedBehavior = useCallback(() => {
    addToast({
      type: 'warning',
      title: 'Erro inesperado',
      description: 'Por favor, atualize seu navegador'
    })
  }, [addToast])

  const handleGraphQLRejection = useCallback(
    (actions?: IGraphQLRejection) => {
      if (actions) {
        addToast({
          type: 'warning',
          title: 'Erro inesperado',
          description: 'Por favor, tente novamente',
          shouldConfirm: actions.shouldConfirm,
          onConfirm: actions.onConfirm
        })

        return
      }

      addToast({
        type: 'warning',
        title: 'Erro inesperado',
        description: 'Por favor, tente novamente'
      })
    },
    [addToast]
  )

  const removeToast = useCallback((id: string) => {
    setMessages(state => state.filter(s => s.id !== id))
  }, [])

  const providerValue = useMemo(
    () => ({
      messages,
      addToast,
      handleApolloError,
      handleUnexpectedBehavior,
      handleGraphQLRejection,
      removeToast
    }),
    [
      messages,
      addToast,
      handleApolloError,
      handleUnexpectedBehavior,
      handleGraphQLRejection,
      removeToast
    ]
  )

  return (
    <Context.Provider value={providerValue}>
      <div data-testid="toast-context-provider">
        <Toast messages={messages} data-testid="toast" />

        {children}
      </div>
    </Context.Provider>
  )
}

function useToastContext(): ToastContextData {
  return useContext(Context)
}

export { ToastContext, useToastContext }
