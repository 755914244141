import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState
} from 'react'

import { Loader } from 'components/Loader'

interface LoaderContextData {
  visible: boolean
  showLoader: () => void
  hideLoader: () => void
  defineTexts: (texts: ILoadingTexts) => void
}

interface ILoadingTexts {
  title: string
  description: string
}

const Context = createContext<LoaderContextData>({} as LoaderContextData)

interface Props {
  children: ReactNode
}

function LoaderContext({ children }: Props) {
  const [visible, setVisible] = useState(false)

  const [texts, setTexts] = useState<ILoadingTexts | null>(null)

  const defineTexts = useCallback((newTexts: ILoadingTexts) => {
    setTexts(newTexts)
  }, [])

  const showLoader = useCallback(() => {
    setVisible(true)
  }, [])

  const hideLoader = useCallback(() => {
    setVisible(false)
    setTexts(null)
  }, [])

  const providerValue = useMemo(
    () => ({
      visible,
      showLoader,
      hideLoader,
      defineTexts
    }),
    [visible, showLoader, hideLoader, defineTexts]
  )

  return (
    <Context.Provider value={providerValue}>
      <div data-testid="loader-context-provider">
        {visible && (
          <Loader title={texts?.title} description={texts?.description} />
        )}

        {children}
      </div>
    </Context.Provider>
  )
}

function useLoaderContext(): LoaderContextData {
  return useContext(Context)
}

export { LoaderContext, useLoaderContext }
