import { createContext, useMemo, ReactNode } from 'react'

import { ThemeProvider, DefaultTheme } from 'styled-components'

import { theme } from 'styles/theme'

interface ThemeContextData {
  theme: DefaultTheme
}

const Context = createContext({} as ThemeContextData)

interface Props {
  children: ReactNode
}

function ThemeContext({ children }: Props) {
  const providerValue = useMemo(
    () => ({
      theme
    }),
    []
  )

  return (
    <ThemeProvider theme={theme}>
      <Context.Provider value={providerValue}>{children}</Context.Provider>
    </ThemeProvider>
  )
}

export { ThemeContext }
