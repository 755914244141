import { useState, ReactNode } from 'react'

import { ApolloProvider } from '@apollo/client'

import { ThemeProvider } from '@raiadrogasil/pulso-react-components'

import { client } from 'services/graphql'

import { ThemeContext } from 'contexts/Theme'
import { LoaderContext } from 'contexts/Loader'
import { AnalyticsContext } from 'contexts/Analytics'
import { FirstJourneyContext } from 'contexts/Journey/First'
import { SecondJourneyContext } from 'contexts/Journey/Second'
import { AuthContext } from 'contexts/Auth'
import { RecoverPasswordContext } from 'contexts/RecoverPassword'
import { SideMenuContext } from 'contexts/Layout/SideMenu'
import { HeaderContext } from 'contexts/Layout/Header'
import { ToastContext } from 'contexts/Toast'
import { DialogConfirmationContext } from 'contexts/DialogConfirmation'

interface Props {
  children: ReactNode
}

export function Contexts({ children }: Props) {
  const [clientGraphql, setClientGraphql] = useState({
    client
  })

  return (
    <ApolloProvider client={clientGraphql.client()}>
      <ThemeProvider theme="whitelabel">
        <ThemeContext>
          <LoaderContext>
            <AnalyticsContext>
              <FirstJourneyContext>
                <SecondJourneyContext>
                  <AuthContext setClient={setClientGraphql}>
                    <RecoverPasswordContext>
                      <SideMenuContext>
                        <HeaderContext>
                          <ToastContext>
                            <DialogConfirmationContext>
                              {children}
                            </DialogConfirmationContext>
                          </ToastContext>
                        </HeaderContext>
                      </SideMenuContext>
                    </RecoverPasswordContext>
                  </AuthContext>
                </SecondJourneyContext>
              </FirstJourneyContext>
            </AnalyticsContext>
          </LoaderContext>
        </ThemeContext>
      </ThemeProvider>
    </ApolloProvider>
  )
}
