export const ROUTE_HOME = '/'
export const ROUTE_CATEGORIES = '/categories'
export const ROUTE_FIRST_JOURNEY = '/first/register'

export const ROUTE_EDIT_BILLING_ADDRESS = '/edit/address'
export const ROUTE_EDIT_COMMERCIAL_DATA = '/edit/commercial'
export const ROUTE_EDIT_CONTRACT = '/edit/contract'

export const ROUTE_LOGIN = '/login'
export const ROUTE_RECOVER_PASSWORD = '/recover-password'

export const ROUTE_SECOND_JOURNEY = '/second/register'

export const ROUTE_ADMIN_HUNTERS_LIST = '/admin'
export const ROUTE_ADMIN_CREATE_HUNTER = '/admin/create-hunter'
export const ROUTE_ADMIN_HUNTER_PROFILE = '/admin/hunter/profile'

export const ROUTE_HUNTER_SELLERS_LIST = '/hunter'
export const ROUTE_HUNTER_SELLER_CONTRACT = '/hunter/seller/contract'

export const ROUTE_NOT_FOUND_ERROR = '/error/not-found'
export const ROUTE_GENERIC_ERROR = '/error/generic'
export const ROUTE_UNEXPECTED_ERROR = '/error/unexpected'
export const ROUTE_OFFLINE_ERROR = '/error/offline'
export const ROUTE_FIRST_SESSION_EXPIRED = '/error/first/session-expired'
export const ROUTE_SECOND_SESSION_EXPIRED = '/error/second/session-expired'

export const URL_HOME_LANDING_PAGE = 'https://www.lojistard.com.br/'
