export const GA_CATEGORY_FIRST_JOURNEY = 'primeira-jornada'

export const PAGEVIEW_SESSION_EXPIRED = 'sessao-expirada'
export const PAGEVIEW_HOME = 'home'

export const PAGEVIEW_CATEGORIES = 'categorias'
export const PAGEVIEW_HUNTING = 'como-chegou'
export const PAGEVIEW_VALIDATE_SELLER = 'validar-cnpj'
export const PAGEVIEW_REGISTRATION_DATA = 'dados-de-cadastro'
export const PAGEVIEW_CODE_VALIDATE = 'validar-codigo'
export const PAGEVIEW_COMMERCIAL_DATA = 'dados-da-empresa'
export const PAGEVIEW_BILLING_ADDRESS = 'endereco-faturamento'
export const PAGEVIEW_CREATE_PASSWORD = 'criar-senha'
export const PAGEVIEW_SUCCESS = 'sucesso'

export const GA_CATEGORY_SECOND_JOURNEY = 'segunda-jornada'
export const PAGEVIEW_LOGIN = 'login'
export const PAGEVIEW_START = 'boas-vindas'
export const PAGEVIEW_CONTRACT = 'contratos'
export const PAGEVIEW_INTEGRATOR = 'integradora'
export const PAGEVIEW_EDIT_COMMERCIAL_DATA = 'editar-dados-da-empresa'
export const PAGEVIEW_EDIT_BILLING_ADDRESS = 'editar-endereco-faturamento'
export const PAGEVIEW_EDIT_CONTRACT = 'editar-termos-contratos'

export const PAGEVIEW_ERROR_UNEXPECTED = 'erro-nao-experado'
export const PAGEVIEW_ERROR_OFFLINE = 'sistema-fora-do-ar'
export const PAGEVIEW_ERROR_GENERIC = 'erro-generico'
export const PAGEVIEW_ERROR_NOT_FOUND = 'pagina-nao-encontrada'

export const PAGEVIEW_COMPANYDATA = 'anexar-documentos'
export const PAGEVIEW_CONTACTS = 'dados-do-responsavel'
export const PAGEVIEW_FINANCIAL = 'dados-financeiros'
export const PAGEVIEW_SECOND_SUCCESS = 'sucesso'
export const PAGEVIEW_INTEGRATION = 'em-integracao'
