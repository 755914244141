import { Container } from './styles'

interface Props {
  className?: string
  children: React.ReactNode
}

export function Backdrop({ className, children }: Props) {
  return (
    <Container className={className} data-testid="backdrop">
      {children}
    </Container>
  )
}

Backdrop.defaultProps = {
  className: undefined
}
