import { useCallback } from 'react'

import { useSideMenuContext } from 'contexts/Layout/SideMenu'

import { Container } from './styles'

export function ButtonMenu() {
  const { menuVisible, toggleSideMenu } = useSideMenuContext()

  const handleOnClick = useCallback(() => {
    toggleSideMenu()
  }, [toggleSideMenu])

  return (
    <Container
      opened={menuVisible}
      onClick={handleOnClick}
      data-testid="button-menu"
      aria-expanded={menuVisible}
    >
      <i
        className={menuVisible ? 'rdicon-dismiss' : 'rdicon-menu'}
        title={menuVisible ? 'Ocultar Menu' : 'Exibir Menu'}
      />
    </Container>
  )
}
