import { useCallback, useEffect } from 'react'

import { SpringValue } from 'react-spring'

import { IToastType, IToastMessage, useToastContext } from 'contexts/Toast'

import { Container } from './styles'

interface Props {
  message: IToastMessage
  style?: {
    opacity: SpringValue<string>
  }
}

export function Message({ message, style }: Props) {
  const { removeToast } = useToastContext()

  useEffect(() => {
    if (!message.shouldConfirm) {
      const timer = setTimeout(() => {
        removeToast(message.id)
      }, 3000)

      return () => {
        clearTimeout(timer)
      }
    }

    return undefined
  }, [message, removeToast])

  const handleIconClass = useCallback((icon: IToastType): string => {
    switch (icon) {
      case 'success':
        return 'rdicon-checkmark-circle'

      case 'info':
      case 'error':
        return 'rdicon-info-circle'

      default:
        return 'rdicon-warning'
    }
  }, [])

  const handleOnClick = useCallback(() => {
    removeToast(message.id)

    message.onConfirm && message.onConfirm()
  }, [removeToast, message])

  return (
    <Container
      type={message.type}
      description={message.description}
      style={style}
      data-testid="toast-message"
    >
      <i className={handleIconClass(message.type)} />

      <div>
        {message.title && <strong>{message.title}</strong>}

        {message.description && <p>{message.description}</p>}
      </div>

      <button
        type="button"
        className={message.shouldConfirm ? 'outline' : ''}
        onClick={handleOnClick}
        data-testid="confirm-button"
      >
        {!message.shouldConfirm ? <i className="rdicon-dismiss" /> : 'ok'}
      </button>
    </Container>
  )
}

Message.defaultProps = {
  style: undefined
}
