export const tokens = {
  brand_100: '#eaf7f0',
  brand_200: '#abdec3',
  brand_500: '#037236',
  brand_700: '#004620',
  brand_900: '#001b0d',

  global_background_100: '#f4f4f6',
  global_background_400: '#9398ab',
  global_background_600: '#5d6075',
  global_background_800: '#3a3c47',
  global_background_black: '#000000',
  global_background_white: '#ffffff',

  global_content_300: '#d5d7e0',
  global_content_400: '#9398ab',
  global_content_500: '#6a7086',
  global_content_700: '#4e5161',
  global_content_900: '#2c2e38',

  global_feedback_positive_100: '#e3f7ec',
  global_feedback_positive_500: '#057d3b',

  global_feedback_informative_100: '#ddeffd',
  global_feedback_informative_500: '#006dc7',

  global_feedback_negative_500: '#d0007d',

  global_feedback_warning_500: '#dfa100',

  global_feedback_highlight_500: '#ce1340',

  font_family: '"Open Sans", sans-serif',

  font_weight_300: '300',
  font_weight_400: '400',
  font_weight_500: '500',
  font_weight_600: '600',
  font_weight_700: '700',

  font_size_10: '10px',
  font_size_12: '12px',
  font_size_14: '14px',
  font_size_16: '16px',
  font_size_18: '18px',
  font_size_21: '21px',
  font_size_24: '24px',
  font_size_28: '28px',
  font_size_32: '32px',
  font_size_36: '36px',
  font_size_44: '44px',
  font_size_48: '48px',
  font_size_60: '60px',

  line_height_100: '100%',
  line_height_125: '125%',
  line_height_150: '150%',
  line_height_175: '175%',
  line_height_200: '200%'
}
