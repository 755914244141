import gql from 'graphql-tag'

export interface IRefreshTokenRequest {
  refreshToken: string
}

export interface IRefreshTokenResponse {
  refreshToken: {
    expires_in: string
    access_token: string
    refresh_token: string
  }
}

export const REFRESH_TOKEN = gql`
  query refreshToken($refreshToken: String!) {
    refreshToken(input: { refresh_token: $refreshToken }) {
      expires_in
      access_token
      refresh_token
    }
  }
`
