import { Typography } from './styles'

interface Props {
  type: 'small' | 'medium' | 'large' | 'huge' | 'giant'
  children: string
}

export function Heading({ type, children }: Props) {
  return <Typography type={type}>{children}</Typography>
}
