import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'

import { useNavigate } from 'react-router-dom'

import { ROUTE_FIRST_SESSION_EXPIRED } from 'constants/routes'

import {
  IFirstJourney,
  IRegistrationData,
  ICodeValidation,
  ICommercialData,
  IBillingAddressData
} from 'interfaces/FirstJourney'

import {
  getLocalStorageItem,
  setLocalStorageItem,
  removeLocalStorageItem
} from 'utils/storage/local'

interface Session {
  startedIn: number
  expiresIn: number
  isExpired: boolean
}

interface FirstJourneyContextData {
  session: Session | null
  step: number | null
  registration: IFirstJourney | null
  isSessionExpired: () => boolean
  previousStep: () => void
  nextStep: () => void
  jumpToStep: (step: 2 | 4 | 5 | 6) => void
  setHunterId: (value: string) => void
  setCnpj: (value: string) => void
  setRegistrationData: (data: IRegistrationData) => void
  setCodeValidationData: (data: ICodeValidation) => void
  setCommercialData: (data: ICommercialData) => void
  setBillingAddressData: (data: IBillingAddressData) => void
  initJourney: () => void
  resetJourney: () => void
}

const Context = createContext<FirstJourneyContextData>(
  {} as FirstJourneyContextData
)

interface Props {
  children: ReactNode
}

function FirstJourneyContext({ children }: Props) {
  const navigate = useNavigate()

  const [session, setSession] = useState<Session | null>(() => {
    return getLocalStorageItem('RD@Onboarding:FirstJourney:session') || null
  })

  const [step, setStep] = useState<number | null>(() => {
    const storagedStep = getLocalStorageItem('RD@Onboarding:FirstJourney:step')

    return Number(storagedStep)
  })

  const [registration, setRegistration] = useState<IFirstJourney | null>(() => {
    return (
      getLocalStorageItem('RD@Onboarding:FirstJourney:registration') || null
    )
  })

  useEffect(() => {
    if (session?.isExpired) navigate(ROUTE_FIRST_SESSION_EXPIRED)
  }, [session, navigate])

  const clearData = useCallback(() => {
    removeLocalStorageItem('RD@Onboarding:FirstJourney:step')
    removeLocalStorageItem('RD@Onboarding:FirstJourney:registration')

    setStep(null)
    setRegistration(null)
  }, [])

  const initSession = useCallback(() => {
    removeLocalStorageItem('RD@Onboarding:FirstJourney:session')

    const startedDate = new Date()
    const expirationDate = new Date(startedDate)

    expirationDate.setTime(expirationDate.getTime() + 60 * 60 * 1000)

    const startedIn = startedDate.getTime()
    const expiresIn = expirationDate.getTime()

    setSession({ startedIn, expiresIn, isExpired: false })

    setLocalStorageItem('RD@Onboarding:FirstJourney:session', {
      startedIn,
      expiresIn
    })
  }, [])

  const resetSession = useCallback(() => {
    removeLocalStorageItem('RD@Onboarding:FirstJourney:session')

    setSession(null)
  }, [])

  const isSessionExpired = useCallback(() => {
    let isExpired = false

    if (session) {
      const date = new Date()
      const timestamp = date.getTime()

      if (timestamp > session.expiresIn) {
        isExpired = true

        setSession({ ...session, isExpired })
        clearData()
      }
    }

    return isExpired
  }, [session, clearData])

  const initJourney = useCallback(() => {
    removeLocalStorageItem('RD@Onboarding:FirstJourney:step')
    removeLocalStorageItem('RD@Onboarding:FirstJourney:registration')

    setStep(0)
    setRegistration(null)
    initSession()

    setLocalStorageItem('RD@Onboarding:FirstJourney:step', 0)
  }, [initSession])

  const resetJourney = useCallback(() => {
    clearData()
    resetSession()
  }, [clearData, resetSession])

  const previousStep = useCallback(() => {
    if (!isSessionExpired()) {
      window.scrollTo({ top: 0 })

      setStep(currentStep => {
        if (currentStep && currentStep >= 2) {
          setLocalStorageItem(
            'RD@Onboarding:FirstJourney:step',
            currentStep - 1
          )

          return currentStep - 1
        }

        initJourney()

        return null
      })
    }
  }, [isSessionExpired, initJourney])

  const nextStep = useCallback(() => {
    if (!isSessionExpired()) {
      window.scrollTo({ top: 0 })

      setStep(currentStep => {
        if (currentStep) {
          setLocalStorageItem(
            'RD@Onboarding:FirstJourney:step',
            currentStep + 1
          )

          return currentStep + 1
        }

        setLocalStorageItem('RD@Onboarding:FirstJourney:step', '1')

        return 1
      })
    }
  }, [isSessionExpired])

  const jumpToStep = useCallback(
    (stepToJump: 2 | 4 | 5 | 6) => {
      if (!isSessionExpired()) {
        window.scrollTo({ top: 0 })

        setStep(stepToJump)

        setLocalStorageItem(
          'RD@Onboarding:FirstJourney:step',
          String(stepToJump)
        )
      }
    },
    [isSessionExpired]
  )

  const setHunterId = useCallback((value: string) => {
    const data = {
      hunterId: value,
      cnpj: '',
      registrationData: null,
      codeValidation: null,
      commercialData: null,
      billingAddressData: null
    }

    setRegistration(data)

    setLocalStorageItem('RD@Onboarding:FirstJourney:registration', data)
  }, [])

  const setCnpj = useCallback(
    (value: string) => {
      if (registration) {
        setRegistration({ ...registration, cnpj: value })

        setLocalStorageItem('RD@Onboarding:FirstJourney:registration', {
          ...registration,
          cnpj: value
        })
      }
    },
    [registration]
  )

  const setRegistrationData = useCallback(
    (data: IRegistrationData) => {
      if (registration) {
        setRegistration({ ...registration, registrationData: data })

        setLocalStorageItem('RD@Onboarding:FirstJourney:registration', {
          ...registration,
          registrationData: data
        })
      }
    },
    [registration]
  )

  const setCodeValidationData = useCallback(
    (data: ICodeValidation) => {
      if (registration) {
        setRegistration({ ...registration, codeValidation: data })

        setLocalStorageItem('RD@Onboarding:FirstJourney:registration', {
          ...registration,
          codeValidation: data
        })
      }
    },
    [registration]
  )

  const setCommercialData = useCallback(
    (data: ICommercialData) => {
      if (registration) {
        setRegistration({ ...registration, commercialData: data })

        setLocalStorageItem('RD@Onboarding:FirstJourney:registration', {
          ...registration,
          commercialData: data
        })
      }
    },
    [registration]
  )

  const setBillingAddressData = useCallback(
    (data: IBillingAddressData) => {
      if (registration) {
        setRegistration({ ...registration, billingAddressData: data })

        setLocalStorageItem('RD@Onboarding:FirstJourney:registration', {
          ...registration,
          billingAddressData: data
        })
      }
    },
    [registration]
  )

  const providerValue = useMemo(
    () => ({
      session,
      step,
      registration,
      isSessionExpired,
      previousStep,
      nextStep,
      jumpToStep,
      setHunterId,
      setCnpj,
      setRegistrationData,
      setCodeValidationData,
      setCommercialData,
      setBillingAddressData,
      initJourney,
      resetJourney
    }),
    [
      session,
      step,
      registration,
      isSessionExpired,
      previousStep,
      nextStep,
      jumpToStep,
      setHunterId,
      setCnpj,
      setRegistrationData,
      setCodeValidationData,
      setCommercialData,
      setBillingAddressData,
      initJourney,
      resetJourney
    ]
  )

  return <Context.Provider value={providerValue}>{children}</Context.Provider>
}

function useFirstJourneyContext(): FirstJourneyContextData {
  return useContext(Context)
}

export { FirstJourneyContext, useFirstJourneyContext }
