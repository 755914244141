import styled, { css } from 'styled-components'

import { rgba } from 'polished'

export const Container = styled.div`
  ${props => css`
    align-items: center;
    background-color: ${rgba(props.theme.colors.global.background.white, 0.75)};
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: center;
    overflow: hidden;
    position: fixed;
    text-align: center;
    width: 100%;
    z-index: 1030;

    .loader,
    .loader:after {
      border-radius: 50%;
      height: 10em;
      width: 10em;
    }

    .loader {
      border-top: 1.1em solid rgba(255, 255, 255, 0.2);
      border-right: 1.1em solid rgba(255, 255, 255, 0.2);
      border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
      border-left: 1.1em solid ${props.theme.colors.brand[500]};
      margin: auto;
      font-size: ${props.theme.font.size[10]};
      position: relative;
      text-indent: -9999em;
      animation: load8 1.1s infinite linear;
      -webkit-transform: translateZ(0);
      -ms-transform: translateZ(0);
      transform: translateZ(0);
      -webkit-animation: load8 1.1s infinite linear;
    }

    @-webkit-keyframes load8 {
      0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
      }
      100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }

    @keyframes load8 {
      0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
      }
      100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }
  `}
`
