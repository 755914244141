import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'

import { useLocation } from 'react-router-dom'

import { ROUTE_LOGIN } from 'constants/routes'

import { Header } from 'components/Layout/Header'

interface HeaderContextData {
  showHeader: () => void
  hideHeader: () => void
}

const Context = createContext<HeaderContextData>({} as HeaderContextData)

interface Props {
  children: ReactNode
}

function HeaderContext({ children }: Props) {
  const location = useLocation()

  const [visible, setVisible] = useState(true)

  const showHeader = useCallback(() => {
    setVisible(true)
  }, [])

  const hideHeader = useCallback(() => {
    setVisible(false)
  }, [])

  useEffect(() => {
    const pathUrl = location.pathname

    if (pathUrl === ROUTE_LOGIN) hideHeader()
    else showHeader()
  }, [location, hideHeader, showHeader])

  const providerValue = useMemo(
    () => ({ showHeader, hideHeader }),
    [showHeader, hideHeader]
  )

  return (
    <Context.Provider value={providerValue}>
      <div data-testid="header-context-provider">
        {visible && <Header />}

        {children}
      </div>
    </Context.Provider>
  )
}

function useHeaderContext(): HeaderContextData {
  return useContext(Context)
}

export { HeaderContext, useHeaderContext }
