import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState
} from 'react'

import { ApolloClient, NormalizedCacheObject } from '@apollo/client'

import { ROUTE_LOGIN } from 'constants/routes'

import { client } from 'services/graphql'
import { ILoginResponse } from 'services/graphql/mutations/Login'

import { useSecondJourneyContext } from 'contexts/Journey/Second'

import { useLogout } from 'hooks/graphql/queries/useLogout'

import { ISeller, IHunter, IAdmin } from 'interfaces/User'

import { RolesEnum } from 'enums/User'

import {
  getLocalStorageItem,
  setLocalStorageItem,
  removeLocalStorageItem
} from 'utils/storage/local'

interface Auth {
  expiresIn: number
  token: string
  refreshToken: string
}

interface UserAuth {
  auth: Auth
  data: {
    id: string
    code?: string
    enrollment?: string
    fullName: string
    email: string
    role: keyof typeof RolesEnum
  }
}

interface AuthContextData {
  user: UserAuth | null
  error: string
  customErrorAuth: (text: string) => void
  setUserData: (data: ILoginResponse) => void
  signOut: () => void
}

const Context = createContext<AuthContextData>({} as AuthContextData)

interface Props {
  children: ReactNode
  setClient?: React.Dispatch<
    React.SetStateAction<{
      client: () => ApolloClient<NormalizedCacheObject>
    }>
  >
}

function AuthContext({ children, setClient }: Props) {
  const { initJourney, setUserHistoryData } = useSecondJourneyContext()

  const { logout } = useLogout()

  const [error, setError] = useState('')

  const [user, setUser] = useState<UserAuth | null>(() => {
    return getLocalStorageItem('RD@Onboarding:user') || null
  })

  const handleUserAsSeller = useCallback(
    (data: ISeller, auth: Auth) => {
      const user = {
        auth,
        data: {
          id: data.id,
          fullName: data.contact.fullName,
          email: data.contact.email,
          role: RolesEnum.SELLER
        }
      }

      setUser(user)
      setLocalStorageItem('RD@Onboarding:user', user)

      if (data.journey.journey === 'START_JOURNEY') {
        initJourney()
        setUserHistoryData(data, 0)
      } else {
        const currentStep = Number(data.journey.numberJourney) + 1

        setUserHistoryData(data, currentStep)
      }
    },
    [initJourney, setUserHistoryData]
  )

  const handleUserAsHunter = useCallback((data: IHunter, auth: Auth) => {
    const user = {
      auth,
      data: {
        id: data.id,
        code: data.code,
        enrollment: data.enrollment,
        fullName: data.name,
        email: data.email,
        role: RolesEnum.HUNTER
      }
    }

    setUser(user)
    setLocalStorageItem('RD@Onboarding:user', user)
  }, [])

  const handleUserAsAdmin = useCallback((data: IAdmin, auth: Auth) => {
    const user = {
      auth,
      data: {
        id: data.id,
        enrollment: data.enrollment,
        fullName: data.name,
        email: data.email,
        role: RolesEnum.ADMIN
      }
    }

    setUser(user)
    setLocalStorageItem('RD@Onboarding:user', user)
  }, [])

  const setUserData = useCallback(
    (data: ILoginResponse) => {
      const startedDate = new Date()
      const expirationDate = new Date(startedDate)
      expirationDate.setSeconds(
        expirationDate.getSeconds() + Number(data.login.expires_in)
      )

      const auth = {
        expiresIn: expirationDate.getTime(),
        token: data.login.access_token,
        refreshToken: data.login.refresh_token
      }

      if (data.login.getSeller) {
        handleUserAsSeller(data.login.getSeller, auth)
        return
      }

      if (data.login.hunter) {
        handleUserAsHunter(data.login.hunter, auth)
        return
      }

      if (data.login.admin) {
        handleUserAsAdmin(data.login.admin, auth)
      }
    },
    [handleUserAsSeller, handleUserAsHunter, handleUserAsAdmin]
  )

  const signOut = useCallback(async () => {
    await logout({
      variables: {
        refreshToken: String(user?.auth?.refreshToken)
      }
    })

    setUser(null)
    setError('')

    removeLocalStorageItem('RD@Onboarding:user')

    if (setClient) {
      setClient({
        client
      })
    }

    window.location.replace(ROUTE_LOGIN)
  }, [logout, user, setClient])

  const customErrorAuth = useCallback((text: string) => {
    setError(text)
  }, [])

  const providerValue = useMemo(
    () => ({
      user,
      error,
      customErrorAuth,
      setUserData,
      signOut
    }),
    [user, error, customErrorAuth, setUserData, signOut]
  )

  return (
    <Context.Provider value={providerValue}>
      <div data-testid="auth-context-provider">{children}</div>
    </Context.Provider>
  )
}

function useAuthContext(): AuthContextData {
  return useContext(Context)
}

export { AuthContext, useAuthContext }

AuthContext.defaultProps = {
  setClient: undefined
}
