import FontsStyle from 'styles/fonts'
import GlobalStyle from 'styles/global'
import DesignSystem from 'styles/libs/design-system'

export function Styles() {
  return (
    <>
      <FontsStyle />
      <GlobalStyle />
      <DesignSystem />
    </>
  )
}
