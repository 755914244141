import styled, { css } from 'styled-components'

export const Wrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
`

export const Container = styled.div`
  ${props => css`
    background-color: ${props.theme.colors.global.background.white};
    border-radius: 10px;
    margin: 0 16px;
    padding: 32px;
    width: 564px;

    @media (max-width: 768px) {
      padding: 16px;
    }
  `}
`

export const Title = styled.h6`
  ${props => css`
    color: ${props.theme.colors.global.background.black};
    font-size: ${props.theme.font.size[24]};
    font-weight: ${props.theme.font.weight[700]};
    line-height: ${props.theme.line.height[125]};

    @media (max-width: 768px) {
      font-size: ${props.theme.font.size[18]};
    }
  `}
`

export const CloseButton = styled.button`
  ${props => css`
    align-items: center;
    background-color: transparent;
    border: unset;
    color: ${props.theme.colors.global.background.black};
    display: flex;
    height: 32px;
    justify-content: center;
    margin: 0;
    padding: 0;
    width: 32px;

    > i {
      font-size: ${props.theme.font.size[24]};
    }
  `}
`
