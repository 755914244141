import styled, { css } from 'styled-components'

interface Props {
  opened: boolean
}

export const Container = styled.div<Props>`
  ${props => css`
    border-color: ${props.theme.colors.brand[500]};
    border-radius: 8px;
    border-style: solid;
    border-width: 1px;

    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;

    height: 48px;
    width: 48px;

    @media (max-width: 768px) {
      height: 40px;
      width: 40px;
    }

    @media (min-width: 993px) and (max-width: 1400px) {
      height: 36px;
      width: 36px;
    }

    ${props.opened &&
    css`
      border-color: ${props.theme.colors.global.content[400]};
    `}

    i {
      color: ${props.theme.colors.brand[500]};
      font-size: ${props.theme.font.size[24]};

      ${props.opened &&
      css`
        color: ${props.theme.colors.global.content[400]};
      `}
    }
  `}
`
