import { createGlobalStyle, css } from 'styled-components'

import 'bootstrap/dist/css/bootstrap.min.css'

import 'swiper/swiper.min.css'
import 'swiper/swiper-bundle.min.css'

export default createGlobalStyle`
  ${props => css`
    * {
      margin: 0;
      padding: 0;
      box-sizing: border-box;
    }

    html {
      scroll-behavior: smooth;
    }

    body {
      color: ${props.theme.colors.global.content[500]};
      font-family: ${props.theme.font.family};
      font-size: ${props.theme.font.size[14]};
      font-weight: ${props.theme.font.weight[400]};

      &.no-overflow {
        overflow: hidden;
      }

      &::-webkit-scrollbar {
        width: 10px;
      }

      &::-webkit-scrollbar-track {
        background-color: ${props.theme.colors.global.background[100]};
      }

      &::-webkit-scrollbar-thumb {
        background-color: ${props.theme.colors.global.content[300]};
      }
    }

    ol,
    ul,
    dl {
      list-style: none;
      margin: 0;
      padding: 0;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
      margin: 0;
    }

    button {
      cursor: pointer;
    }

    a {
      text-decoration: unset;
    }

    b {
      font-weight: ${props.theme.font.weight[500]};
    }

    strong {
      font-weight: ${props.theme.font.weight[700]};
    }
  `}
`
