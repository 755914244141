import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState
} from 'react'

import {
  SellerData,
  SellerHistoryData,
  ContactDataType
} from 'interfaces/SecondJourney'

import {
  getLocalStorageItem,
  setLocalStorageItem,
  removeLocalStorageItem
} from 'utils/storage/local'

interface SecondJourneyContextData {
  step: number
  registrationStep: number
  afterRegistration: SellerData | null
  previousStep: () => void
  nextStep: () => void
  updateSellerData: (seller: SellerData) => void
  jumpToStep: (step: number) => void
  jumpToRegistrationStep: () => void
  setUserHistoryData: (seller: SellerHistoryData, step: number) => void
  initJourney: () => void
}

const Context = createContext<SecondJourneyContextData>(
  {} as SecondJourneyContextData
)

interface Props {
  children: ReactNode
}

function SecondJourneyContext({ children }: Props) {
  const [step, setStep] = useState(() => {
    const storagedStep = getLocalStorageItem('RD@Onboarding:SecondJourney:step')

    return storagedStep ? Number(storagedStep) : 0
  })

  const [registrationStep, setRegistrationStep] = useState(() => {
    const storagedRegistrationStep = getLocalStorageItem(
      'RD@Onboarding:SecondJourney:registrationStep'
    )

    return storagedRegistrationStep ? Number(storagedRegistrationStep) : 0
  })

  const [afterRegistration, setAfterRegistration] = useState<SellerData | null>(
    () => {
      const sellerHistory: SellerData = {}

      const { contractData } = getLocalStorageItem(
        'RD@Onboarding:SecondJourney:contract'
      )

      if (contractData) {
        sellerHistory.contractData = contractData
      }

      const { userSellerData } = getLocalStorageItem(
        'RD@Onboarding:SecondJourney:userSeller'
      )

      if (userSellerData) {
        sellerHistory.userSellerData = userSellerData
      }

      const { integratorData } = getLocalStorageItem(
        'RD@Onboarding:SecondJourney:integrator'
      )

      if (integratorData) {
        sellerHistory.integratorData = integratorData
      }

      const { contactData } = getLocalStorageItem(
        'RD@Onboarding:SecondJourney:contacts'
      )

      if (contactData) {
        sellerHistory.contactData = contactData
      }

      const { financialData } = getLocalStorageItem(
        'RD@Onboarding:SecondJourney:financialData'
      )

      if (financialData) {
        sellerHistory.financialData = financialData
      }

      const { companyData } = getLocalStorageItem(
        'RD@Onboarding:SecondJourney:companyData'
      )

      if (companyData) {
        sellerHistory.companyData = companyData
      }

      return sellerHistory
    }
  )

  const initJourney = useCallback(() => {
    removeLocalStorageItem('RD@Onboarding:SecondJourney:step')
    removeLocalStorageItem('RD@Onboarding:SecondJourney:registrationStep')
    removeLocalStorageItem('RD@Onboarding:SecondJourney:userSeller')
    removeLocalStorageItem('RD@Onboarding:SecondJourney:integrator')
    removeLocalStorageItem('RD@Onboarding:SecondJourney:contacts')
    removeLocalStorageItem('RD@Onboarding:SecondJourney:financialData')
    removeLocalStorageItem('RD@Onboarding:SecondJourney:companyData')

    setStep(0)
    setRegistrationStep(0)

    setLocalStorageItem('RD@Onboarding:SecondJourney:step', 0)
    setLocalStorageItem('RD@Onboarding:SecondJourney:registrationStep', 0)
  }, [])

  const previousStep = useCallback(() => {
    window.scrollTo({ top: 0 })

    setStep(currentStep => {
      if (currentStep >= 1) {
        setLocalStorageItem('RD@Onboarding:SecondJourney:step', currentStep - 1)

        return currentStep - 1
      }

      return currentStep
    })
  }, [])

  const nextStep = useCallback(() => {
    window.scrollTo({ top: 0 })

    setStep(currentStep => {
      if (currentStep) {
        const forwardStep = currentStep + 1

        if (forwardStep > registrationStep) {
          setLocalStorageItem(
            'RD@Onboarding:SecondJourney:registrationStep',
            forwardStep
          )

          setRegistrationStep(forwardStep)
        }

        setLocalStorageItem('RD@Onboarding:SecondJourney:step', forwardStep)

        return forwardStep
      }

      setLocalStorageItem('RD@Onboarding:SecondJourney:step', '1')

      return 1
    })
  }, [registrationStep])

  const jumpToStep = useCallback((stepToJump: number) => {
    window.scrollTo({ top: 0 })

    setStep(stepToJump)

    setLocalStorageItem('RD@Onboarding:SecondJourney:step', String(stepToJump))
  }, [])

  const jumpToRegistrationStep = useCallback(() => {
    window.scrollTo({ top: 0 })

    setStep(registrationStep)

    setLocalStorageItem(
      'RD@Onboarding:SecondJourney:step',
      String(registrationStep)
    )
  }, [registrationStep])

  const updateSellerData = useCallback(
    (seller: SellerData) => {
      if (seller) {
        if (seller.contractData) {
          setAfterRegistration({
            ...afterRegistration,
            contractData: seller.contractData
          })

          setLocalStorageItem('RD@Onboarding:SecondJourney:contract', {
            integratorData: seller.contractData
          })
        }

        if (seller.integratorData) {
          setAfterRegistration({
            ...afterRegistration,
            integratorData: seller.integratorData
          })

          setLocalStorageItem('RD@Onboarding:SecondJourney:integrator', {
            integratorData: seller.integratorData
          })
        }

        if (seller.contactData) {
          setAfterRegistration({
            ...afterRegistration,
            contactData: seller.contactData
          })

          setLocalStorageItem('RD@Onboarding:SecondJourney:contacts', {
            contactData: seller.contactData
          })
        }

        if (seller.financialData) {
          setAfterRegistration({
            ...afterRegistration,
            financialData: seller.financialData
          })

          setLocalStorageItem('RD@Onboarding:SecondJourney:financialData', {
            financialData: seller.financialData
          })
        }

        if (seller.companyData) {
          setLocalStorageItem('RD@Onboarding:SecondJourney:companyData', {
            companyData: seller.companyData
          })
        }
      }
    },
    [afterRegistration]
  )

  const setUserHistoryData = useCallback(
    (sellerHistory: SellerHistoryData, currentStep: number) => {
      removeLocalStorageItem('RD@Onboarding:SecondJourney:step')
      removeLocalStorageItem('RD@Onboarding:SecondJourney:registrationStep')
      removeLocalStorageItem('RD@Onboarding:SecondJourney:userSeller')
      removeLocalStorageItem('RD@Onboarding:SecondJourney:contract')
      removeLocalStorageItem('RD@Onboarding:SecondJourney:integrator')
      removeLocalStorageItem('RD@Onboarding:SecondJourney:contacts')
      removeLocalStorageItem('RD@Onboarding:SecondJourney:financialData')
      removeLocalStorageItem('RD@Onboarding:SecondJourney:companyData')

      const sellerLogin: SellerData = {}

      sellerLogin.userSellerData = {
        cnpj: sellerHistory.cnpj,
        specialProducts: sellerHistory.specialProducts,
        fullName: sellerHistory.contact.fullName,
        hasAdvisor: sellerHistory.hasAdvisor,
        status: sellerHistory.status
      }

      setLocalStorageItem('RD@Onboarding:SecondJourney:userSeller', {
        userSellerData: sellerLogin.userSellerData
      })

      if (sellerHistory.contractList) {
        sellerLogin.contractData = sellerHistory.contractList

        setLocalStorageItem('RD@Onboarding:SecondJourney:contract', {
          contractData: sellerHistory.contractList
        })
      }

      if (sellerHistory.integrator) {
        const integratorInformation = {
          id: sellerHistory.integrator.id,
          name: sellerHistory.integrator.name,
          identification: sellerHistory.integrator.identification,
          comercialPolitics: sellerHistory.integrator.comercialPolitics,
          followUp: sellerHistory.integrator.followUp,
          endpoint: sellerHistory.integrator.endpoint
        }

        sellerLogin.integratorData = integratorInformation

        setLocalStorageItem('RD@Onboarding:SecondJourney:integrator', {
          integratorData: integratorInformation
        })
      }

      if (sellerHistory.responsibleDataList) {
        const contacts: ContactDataType[] =
          sellerHistory.responsibleDataList.map(contact => {
            return {
              enumType: contact.type,
              fullName: contact.fullName,
              phone: contact.phone,
              email: contact.email
            }
          })

        sellerLogin.contactData = contacts

        setLocalStorageItem('RD@Onboarding:SecondJourney:contacts', {
          contactData: contacts
        })
      }

      if (sellerHistory.financialData) {
        sellerLogin.financialData = sellerHistory.financialData

        setLocalStorageItem('RD@Onboarding:SecondJourney:financialData', {
          financialData: sellerLogin.financialData
        })
      }

      if (sellerHistory.acceptedDocumentsList) {
        const documentsList = sellerHistory.acceptedDocumentsList

        const filteredList = Object.assign(
          {},
          ...documentsList.map(item => {
            return {
              [item.fileName]: item.originalFileName
            }
          })
        )

        sellerLogin.companyData = filteredList

        setLocalStorageItem('RD@Onboarding:SecondJourney:companyData', {
          companyData: filteredList
        })
      }

      const nextStep = currentStep ? currentStep - 1 : 0

      if (sellerHistory.hasAdvisor) {
        jumpToStep(nextStep)
        setRegistrationStep(nextStep)
      } else {
        jumpToStep(currentStep)
        setRegistrationStep(currentStep)
      }

      setAfterRegistration(sellerLogin)
    },
    [jumpToStep]
  )

  const providerValue = useMemo(
    () => ({
      step,
      registrationStep,
      afterRegistration,
      previousStep,
      nextStep,
      jumpToStep,
      jumpToRegistrationStep,
      updateSellerData,
      setUserHistoryData,
      initJourney
    }),
    [
      step,
      registrationStep,
      afterRegistration,
      previousStep,
      nextStep,
      jumpToStep,
      jumpToRegistrationStep,
      updateSellerData,
      setUserHistoryData,
      initJourney
    ]
  )

  return <Context.Provider value={providerValue}>{children}</Context.Provider>
}

function useSecondJourneyContext(): SecondJourneyContextData {
  return useContext(Context)
}

export { SecondJourneyContext, useSecondJourneyContext }
