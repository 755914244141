import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'

import { normalizeString } from 'utils/masks'

import {
  getSessionStorageItem,
  setSessionStorageItem
} from 'utils/storage/session'

declare global {
  interface Window {
    dataLayer: any[]
    $dataLayer: any[]
  }
}

window.dataLayer = window.dataLayer || []
window.$dataLayer = window.$dataLayer || []

interface IPageInfo {
  pagePath?: string
  virtualPageview: string
  pageCategory?: string
  pageType?: string
}

interface CustomEvent {
  eventCategory?: string
  eventAction: string
  eventLabel: string
}

interface AnalyticsContextData {
  category: string
  currentPage: string
  setPageView: (input: IPageInfo) => void
  setCategory: (input: string) => void
  setCurrentPage: (input: string) => void
  setEvent: (input: CustomEvent) => void
}

const Context = createContext<AnalyticsContextData>({} as AnalyticsContextData)

interface Props {
  children: ReactNode
}

function AnalyticsContext({ children }: Props) {
  const [category, updateCategory] = useState('')
  const [currentPage, setCurrentPage] = useState('')

  useEffect(() => {
    const savedCategory = getSessionStorageItem('RD@Onboarding:gaCategory')

    if (!category && savedCategory) {
      updateCategory(savedCategory)
    }
  }, [category])

  const setCategory = (input: string): void => {
    setSessionStorageItem('RD@Onboarding:gaCategory', input)
    updateCategory(input)
  }

  const setEvent = useCallback(
    (customEvent: CustomEvent) => {
      let { eventLabel, eventCategory = '', eventAction = '' } = customEvent

      eventLabel = normalizeString(eventLabel)
      eventCategory = normalizeString(eventCategory || category)
      eventAction = normalizeString(eventAction)

      if (!eventCategory || !currentPage) return

      const event = {
        event: 'ga_event',
        eventCategory: `${eventCategory}_${currentPage}`,
        eventAction,
        eventLabel
      }

      pushEvent(event)
    },
    [category, currentPage]
  )

  const setPageView = useCallback(
    (gaEvent: IPageInfo): void => {
      const { pageCategory } = gaEvent
      const eventCategory = category || normalizeString(pageCategory || '')

      let { virtualPageview } = gaEvent

      virtualPageview = normalizeString(virtualPageview)

      setCurrentPage(virtualPageview)

      const event = {
        event: 'ga_pageview',
        path: `/${eventCategory}/${virtualPageview}`
      }

      pushEvent(event)
    },
    [category]
  )

  const providerValue = useMemo(
    () => ({
      currentPage,
      setCurrentPage,
      category,
      setCategory,
      setPageView,
      setEvent
    }),
    [category, currentPage, setEvent, setPageView]
  )

  return <Context.Provider value={providerValue}>{children}</Context.Provider>
}

function useAnalyticsContext(): AnalyticsContextData {
  return useContext(Context)
}
function pushEvent(event: any) {
  const isProduction = window.location.hostname.match(/.com.br/gi)
  if (isProduction) {
    window.dataLayer.push(event)
  } else {
    // Não dispara para o GA
    window.$dataLayer.push(event)
  }
}

export { AnalyticsContext, useAnalyticsContext }
