import styled, { css } from 'styled-components'

import { isMobile } from 'react-device-detect'

interface Props {
  type: 'small' | 'medium' | 'large' | 'huge' | 'giant'
}

export const Typography = styled.div<Props>`
  ${props => css`
    color: ${props.theme.colors.global.content[900]};
    font-weight: ${props.theme.font.weight[700]};
    letter-spacing: -0.03em;
    line-height: ${props.theme.line.height[150]};

    ${props.type === 'medium' &&
    css`
      font-size: ${props.theme.font.size[16]};
      line-height: ${props.theme.line.height[150]};
    `}

    ${props.type === 'huge' &&
    css`
      font-size: ${props.theme.font.size[24]};

      @media (min-width: 993px) and (max-width: 1400px) {
        font-size: ${props.theme.font.size[21]};
      }
    `}

    ${props.type === 'giant' &&
    css`
      font-size: ${isMobile
        ? props.theme.font.size[32]
        : props.theme.font.size[44]};
      line-height: ${props.theme.line.height[150]};

      @media (min-width: 993px) and (max-width: 1400px) {
        font-size: ${props.theme.font.size[32]};
        line-height: ${props.theme.line.height[150]};
      }
    `}
  `}
`
